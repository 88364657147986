import React, { useEffect, useState } from "react";

import Centralizer from "../Centralizer";
import { Spin } from "../Centralizer/styles";

import { PaymentType } from "../../models/enums/PaymentType";
import { CashHistories as CashHistoriesModel } from "../../models/CashHistories";

import {
  Button,
  Content,
  Title,
  Drawer,
  Row,
  Col,
  Type,
  MoneyIcon,
  TicketIcon,
  OnlineIcon,
  PixIcon,
  DebitCardIcon,
  CreditCardIcon,
  ArrowLeftIcon,
  DownloadIcon,
  ArrowRightIcon,
} from "./styles";

interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedHistory: CashHistoriesModel | null;
}

const InfoNfceCashManagement: React.FC<IProps> = ({
  visible,
  setVisible,
  selectedHistory,
}) => {
  const [selectSalesType, setSelectSalesType] = useState<
    { id: number; totalValue: number; type: number; url: string }[] | null
  >(null);
  const [nfceByType, setNfcesByType] = useState<
    {
      type: number;
      value: number;
      total: number;
      sales: { id: number; totalValue: number; type: number; url: string }[];
    }[]
  >([]);

  useEffect(() => {
    if (selectedHistory && visible) {
      getNfceByType();
    }
  }, [selectedHistory, visible]);

  const onClose = () => {
    setVisible(false);
    setNfcesByType([]);
    setSelectSalesType(null);
  };

  const getNfceByType = async () => {
    const _salesWithNfce = selectedHistory?.sales?.filter((_sale) =>
      _sale?.nfce_url?.includes(".xml")
    );

    const _nfcePaymentByType =
      _salesWithNfce?.reduce((acc, sale) => {
        sale.payments.forEach((_payment) => {
          const type = _payment.type;

          const totalValue =
            (+_payment.amount || 0) - (+sale?.change_amount || 0);

          if (!acc[type]) {
            acc[type] = { type, value: 0, total: 0, sales: [] };
          }

          acc[type].value += totalValue;
          acc[type].sales.push({
            id: sale.id,
            totalValue,
            type,
            url: sale.nfce_url,
          });

          acc[type].total += 1;
        });

        return acc;
      }, {}) || [];

    setNfcesByType(Object.values(_nfcePaymentByType || {}));
  };

  const PaymentTypeLabels: { [key: number]: string } = {
    0: "Dinheiro",
    1: "Crédito",
    2: "Débito",
    3: "Ticket",
    4: "Online",
    5: "Boleto",
    6: "Pix",
  };

  const getType = (_type) => {
    return (
      <>
        {getIcon(_type)}
        <Type type={_type}>{PaymentTypeLabels[_type]}</Type>
      </>
    );
  };

  const getIcon = (_type) => {
    switch (_type) {
      case PaymentType.Ticket:
        return <TicketIcon />;
      case PaymentType.Online:
        return <OnlineIcon />;
      case PaymentType.C_Crédito:
        return <CreditCardIcon />;
      case PaymentType.C_Débito:
        return <DebitCardIcon />;
      case PaymentType.Pix:
        return <PixIcon />;
      default:
        return <MoneyIcon />;
    }
  };

  return (
    <Drawer
      title={<Title>Valor das NFC-e emitidas</Title>}
      visible={visible}
      onClose={() => onClose()}
      className="drawer"
      closable
      width={window.outerWidth < 768 ? "100%" : "40%"}
      footer={<Button onClick={() => onClose()}>Fechar</Button>}
    >
      {false ? (
        <Centralizer>
          <Spin />
        </Centralizer>
      ) : (
        <Content>
          <Row gutter={[4, 4]}>
            <Col sm={12} xs={12} style={{ alignItems: "flex-start" }}>
              <span className="title">Data de Abertura do Caixa</span>
              <span className="body">{selectedHistory?.opened_at}</span>
            </Col>
            <Col sm={12} xs={12} style={{ alignItems: "flex-end" }}>
              <span className="title">Data de Fechamento do Caixa</span>
              <span className="body">{selectedHistory?.closed_at}</span>
            </Col>
          </Row>
          {!selectSalesType ? (
            <>
              <Row>
                <h2>Valor das notas</h2>
              </Row>
              <Row gutter={[4, 4]}>
                <Col sm={6} xs={6} className="table">
                  <span className="title">Método</span>
                </Col>
                <Col sm={6} xs={6} className="table">
                  <span className="title">Valor</span>
                </Col>
                <Col sm={6} xs={6}>
                  <span className="title">Qtd. de notas</span>
                </Col>
                <Col sm={6} xs={6}>
                  <span className="title">Visualizar</span>
                </Col>
              </Row>
              {nfceByType.map((_item, index) => (
                <Row gutter={[4, 0]} key={index}>
                  <Col sm={6} xs={6} className="table">
                    <span>{getType(_item?.type)}</span>
                  </Col>
                  <Col sm={6} xs={6} className="table">
                    <span className="body">R$ {_item?.value?.toFixed(2)}</span>
                  </Col>
                  <Col sm={6} xs={6}>
                    <span className="body">{_item.total}</span>
                  </Col>
                  <Col
                    sm={6}
                    xs={6}
                    onClick={() => setSelectSalesType(_item.sales)}
                  >
                    <span className="body">
                      <ArrowRightIcon />
                    </span>
                  </Col>
                </Row>
              ))}
              <Row style={{ marginTop: "1rem" }}>
                <Col className="total">
                  <span className="title">Total das NFC-e Emitidas</span>
                  <span className="body-total">
                    {"R$ "}
                    {nfceByType
                      ?.reduce((_total, nfce) => _total + (+nfce.value || 0), 0)
                      ?.toFixed(2)}
                  </span>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <ArrowLeftIcon onClick={() => setSelectSalesType(null)} />
                <h2>
                  Vendas
                  <>{` - ${PaymentTypeLabels[selectSalesType[0]?.type]}`}</>
                </h2>
              </Row>
              <Row gutter={[4, 4]}>
                <Col sm={8} xs={8} className="table">
                  <span className="title">Identificação</span>
                </Col>
                <Col sm={8} xs={8} className="table">
                  <span className="title">Valor</span>
                </Col>
                <Col sm={8} xs={8}>
                  <span className="title">XML</span>
                </Col>
              </Row>
              <div
                style={{
                  width: "100%",
                  maxHeight: "20rem",
                  overflow: "auto",
                }}
              >
                {selectSalesType.map((_item, index) => (
                  <Row gutter={[4, 0]} key={index}>
                    <Col sm={8} xs={8} className="table">
                      <span>{_item.id}</span>
                    </Col>
                    <Col sm={8} xs={8} className="table">
                      <span className="body">
                        R$ {_item?.totalValue?.toFixed(2)}
                      </span>
                    </Col>
                    <Col sm={8} xs={8}>
                      <a
                        className="body"
                        href={_item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DownloadIcon />
                      </a>
                    </Col>
                  </Row>
                ))}
              </div>
              <Row style={{ marginTop: "1rem" }}>
                <Col className="total">
                  <span className="title">{`Total das NFC-e Emitidas em ${
                    PaymentTypeLabels[selectSalesType[0]?.type]
                  }`}</span>
                  <span className="body-total">
                    {"R$ "}
                    {selectSalesType
                      ?.reduce(
                        (_total, nfce) => _total + (+nfce.totalValue || 0),
                        0
                      )
                      ?.toFixed(2)}
                  </span>
                </Col>
              </Row>
            </>
          )}
        </Content>
      )}
    </Drawer>
  );
};

export default InfoNfceCashManagement;
