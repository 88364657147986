import React, { useState, useEffect, useCallback } from "react";

import { withRouter, RouteComponentProps } from "react-router-dom";
import { debounce } from "lodash";

import api from "../../../../services/api";
import apiMercury from "../../../../services/apiMercury";
import { verifyPermission } from "../../../../services/auth";
import { useStoreData } from "../../../../hooks/useStoreData";

import { Purchase } from "../../../../models/Purchase/Purchase";
import { PurchaseItems } from "../../../../models/Purchase/PurchaseItems";
import { ProductCategory as ProductCategoryResponse } from "../../../../models/ProductCategory";

import { removeAccentsAndLowerCase } from "../../../../utils/removeAccentsAndCaseSensitive";

import PageContainer from "../../../../containers/PageContainer";
import ShoppingList from "../../../../containers/ShoppingList";
import Centralizer from "../../../../containers/Centralizer";

import GetStore from "../../../../components/GetStore";
import MonetaryInput2 from "../../../../components/MonetaryInput2";
import StoreSelectionPage from "../../../../components/StoreSelectionPage";
import DisabledFlag from "../../../../components/DisabledFlag";

import { Spin, Tooltip, message, notification } from "antd";

import moment, { Moment } from "moment";
import swal from "sweetalert";

import PurchasesImg from "../../../../assets/svg/purchasespage.svg";
import NoData from "../../../../assets/svg/nodatapurchase.svg";

import InfoPurchaseItemsDrawer from "../../../../containers/InfoPurchaseItems";

import OrderPaymentType from "../../../../models/json/OrderPaymentType.json";
import { Page } from "../../../../models/Page";
import { exportCSVFile } from "../../../../services/exportCSVFile";
import locale from "antd/es/date-picker/locale/pt_BR";

import {
  Row,
  Col,
  AddIcon,
  ButtonFilter,
  ButtonSearch,
  BodyList,
  FilterIcon,
  RowHeaderList,
  ColHeaderList,
  ModalFilter,
  ButtonClear,
  GraphIcon,
  Select,
  DownloadIcon,
  Input,
  LupaIcon,
  ColHeaderListButtons,
  StyledLink,
  FooterFilterModal,
  RangePickerAnt,
} from "./styles";

interface ComponentProps extends RouteComponentProps<{}, {}, any> {}

const Shop: React.FC<ComponentProps> = ({ location, history }) => {
  const { state } = location;

  //@ts-ignore
  const store_param = state?.store_param;

  const [productsCategory, setProductsCategory] = useState<
    ProductCategoryResponse[]
  >([]);

  const [purchasesOrder, setPurchasesOrder] = useState<Purchase | null>(null);
  const [purchasesItems, setPurchasesItems] = useState<PurchaseItems[]>([]);
  const [purchases, setPurchases] = useState<Purchase[]>([]);

  const [purchaseId, setPurchaseId] = useState<number>();
  const [store, setStore] = useState<number | null | undefined>(
    +store_param || null
  );

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [allOrders, setAllOrders] = useState(false);
  const [check, setCheck] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(false);
  const [purchaseFilters, setPurchaseFilters] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [filteredProvider, setFilteredProvider] = useState<Purchase[]>([]);

  const { isStoreActive } = useStoreData(store);

  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 10,
    totalElements: 0,
  });

  const { page, size, totalElements } = paginate;

  const [filter, setFilter] = useState<{
    data_inicial: Moment;
    data_final: Moment;
    value_min?: number;
    value_max?: number;
    category?: number;
    product?: number;
    status?: number;
    type?: number;
  }>({
    data_inicial: moment(),
    data_final: moment().add(1, "days"),
    value_min: undefined,
    value_max: undefined,
    category: undefined,
    product: undefined,
    status: undefined,
    type: undefined,
  });

  const [date, setDate] = useState({
    data_inicial: moment().subtract(90, "days"),
    data_final: moment().add(1, "days"),
  });

  const [selectedDate, setSelectedDate] = useState(false);

  useEffect(() => {
    if (state?.storeId) {
      setStore(state.storeId);
      setFilter({
        data_inicial: moment(),
        data_final: moment().add(1, "days"),
        value_min: undefined,
        value_max: undefined,
        category: undefined,
        product: undefined,
        status: undefined,
      });
      setCheck(false);
    }
  }, [state]);

  useEffect(() => {
    const fetchShop = async () => {
      try {
        setLoading(true);
        const { data_inicial, data_final } = {
          data_inicial: date.data_inicial.format("DD/MM/YYYY"),
          data_final: date.data_final.format("DD/MM/YYYY"),
        };

        let updatePurchaseFilter = searchValue ? true : purchaseFilters;

        const UrlBuilder = () => {
          let URL = `purchases?purchaseFilters=${updatePurchaseFilter}&data_inicial=${data_inicial}&data_final=${data_final}`;

          if (allOrders) {
            URL += `&page=${page}&size=${size}&searchAllStoresByUser=true`;

            if (!purchaseFilters) {
              URL = URL.replace(
                `purchaseFilters=${updatePurchaseFilter}`,
                "purchaseFilters=true"
              );
            }

            if (store) {
              URL = `purchases/store/${store}?purchaseFilters=${updatePurchaseFilter}&page=${page}&size=${size}&data_inicial=${data_inicial}&data_final=${data_final}`;
              URL = URL.replace("&searchAllStoresByUser=true", "");
            }
          } else if (store) {
            URL = `purchases/store/${store}?purchaseFilters=${updatePurchaseFilter}&page=${page}&size=${size}&data_inicial=${data_inicial}&data_final=${data_final}`;
          }

          if (selectedDate) {
            URL = `purchases/store/${store}?purchaseFilters=true&data_inicial=${data_inicial}&data_final=${data_final}&page=${page}&size=${size}`

            if (store === 0 || store === null) {
              URL += `&searchAllStoresByUser=true`;
              URL = URL.replace(`/store/${store}`, "");
              if (!purchaseFilters) {
                URL = URL.replace(
                  `purchaseFilters=${updatePurchaseFilter}`,
                  "purchaseFilters=true"
                );
              }
            }
          }

          if (purchaseFilters) {
            if (filter.value_min || filter.value_max) {
              URL += `&min_value=${filter.value_min || 0}&max_value=${
                filter.value_max || 999999
              }`;
            }

            if (filter.category !== undefined && filter.category !== null) {
              URL += `&category_id=${filter.category}`;
            }

            if (filter.status) {
              URL += `&status=${filter.status}`;
            }

            if (filter.type) {
              URL += `&payment_method=${filter.type}`;
            }
          }

          if (searchValue) {
            URL += `&provider_name=${searchValue}`;
          }

          return URL;
        };

        const {
          data: { content, totalElements },
        } = await apiMercury.get(UrlBuilder());
        if (content.length === 0) {
          swal(
            "Nenhum resultado encontrado!",
            "Os valores de provedor não retornaram resultados",
            "warning"
          );
        }

        if (content.length === 0 && purchaseFilters) {
          swal(
            "Nenhum resultado encontrado!",
            "Os valores de filtro não retornaram resultados",
            "warning"
          );
          resetFilter();
          setPurchaseFilters(false);
        } else {
          if (filter.product) {
            const productFilterList = content.filter((_purchase) =>
              _purchase.purchasesItems.some(
                (_product) => _product.product_id === filter.product
              )
            );
            if (productFilterList.length === 0) {
              swal(
                "Nenhum resultado encontrado!",
                "Os valores de filtro não retornaram resultados",
                "warning"
              );
              resetFilter();
              setPurchaseFilters(false);
            } else {
              setPurchases(productFilterList);
            }
          } else {
            setPurchases(content);
            setFilteredProvider(content);
          }
        }

        setPaginate((prev) => ({
          ...prev,
          totalElements,
        }));
        setLoading(false);
      } catch (e) {
        const _message =
          //@ts-ignore
          e?.response?.data?.message || "Houve um erro ao realizar busca";
        message.error(_message);
      }
    };
    if (((store || allOrders) && shouldSearch) || store_param) {
      fetchShop();
      setShouldSearch(false);
    }
  }, [
    store,
    shouldSearch,
    date.data_inicial,
    date.data_final,
    purchaseFilters,
    store_param,
    page,
  ]);

  useEffect(() => {
    const fetchProductsByCategory = async () => {
      try {
        const {
          data: { content },
        } = await api.get("/product_categories/products/purchases");
        const {
          data: { data },
        } = await api.get("/products");

        const productsWithTag = content
          ?.map((category: ProductCategoryResponse) => ({
            ...category,
            products: category?.products
              ?.map((product) => {
                const fullProduct = data?.find(
                  (_product) => _product?.id === product?.id
                );
                return fullProduct ? { ...product, ...fullProduct } : product;
              })
          }))
          ?.filter((_category) => _category?.products?.length > 0);

        setProductsCategory(productsWithTag);
      } catch (e) {
        const _message =
          //@ts-ignore
          e?.response?.data?.message || "Houve um erro ao realizar busca";
        message.error(_message);
      }
    };
    fetchProductsByCategory();
  }, []);

  const searchFilter = () => {
    setAllOrders(check);
    setPurchaseFilters(true);
    setVisible(false);
    setShouldSearch(true);
  };

  const resetPaginate = useCallback(() => {
    setPaginate((oldValues) => ({ ...oldValues, page: 1 }));
    setShouldSearch(true);
  }, []);

  const debouncedSave = useCallback(
    debounce((value) => {
      const lowerCaseValue = removeAccentsAndLowerCase(value).toLowerCase();
      setSearchValue(lowerCaseValue);
      resetPaginate();
    }, 1000),
    [resetPaginate]
  );

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    debouncedSave(value);
  };

  const resetFilter = () => {
    setFilter({
      data_inicial: moment().subtract(90, "days"),
      data_final: moment().add(1, "days"),
      value_min: undefined,
      value_max: undefined,
      category: undefined,
      product: undefined,
      status: undefined,
    });
    setDate({
      data_inicial: moment().subtract(90, "days"),
      data_final: moment().add(1, "days"),
    });
    setAllOrders(false);
    setPurchaseFilters(false);
    setVisible(false);
    setShouldSearch(true);
  };

  const infoModal = async (purchase) => {
    setVisibleInfo(true);
    setLoadingInfo(true);
    try {
      const {
        data: { content },
      } = await apiMercury.get(`/purchases/${purchase.id}`);
      setPurchasesOrder({
        ...content,
      });

      setPurchasesItems(
        content.purchasesItems.map((item) => ({
          id: +item.id,
          product_name: item.product.name,
          quantity: +item.quantity,
          unitary_value: item.unitary_value,
          category_id: item.category_id,
          observation: item?.observation,
          product_id: item.product_id,
          purchase_id: item.purchase_id,
          created_at: item?.created_at,
          deleted_at: item?.deleted_at,
          updated_at: item?.updated_at,
          accounts_payable: content.accountsPayable || [],
        }))
      );
    } catch (e) {
      const _message =
        //@ts-ignore
        e?.response?.data?.message || "Houve um erro ao realizar busca";
      message.error(_message);
    }
    setLoadingInfo(false);
  };

  const addFirstPurchase = () => {
    const purchasesData = {
      purchasesItems,
      purchases,
      purchaseId,
      productsCategory,
      purchasesOrder,
      store,
    };
    history.push({
      pathname: "/new-shop",
      state: purchasesData,
    });
  };

  const downloadCSV = () => {
    if (!purchases || purchases.length === 0) {
      notification.error({
        message: "Nenhum dado disponível para exportar.",
        duration: 5,
      });
      return;
    }

    const headers = {
      id: "ID da Compra",
      store_id: "ID da Loja",
      total: "Total da Compra",
      icms_st_value: "Total de ICMS ST",
      additional_value: "Total de Valor Adicional",
      discount_value: "Total de Desconto",
      purchase_items: "Qtde Itens",
      observation: removeAccentsAndLowerCase("Observação"),
      created_at: removeAccentsAndLowerCase("Data de Criação"),
    };

    const items = purchases.map((item) => ({
      id: item.id,
      store_id: store,
      total: item.total,
      icms_st_value: item.icms_st_value,
      additional_value: item.additional_value,
      discount_value: item.discount_value,
      purchase_items: item.purchasesItems.length,
      observation: removeAccentsAndLowerCase(item.observation),
      created_at: item?.created_at,
    }));

    exportCSVFile(headers, items, `dados-compras-loja=${store}`);

    notification.success({
      message: "Arquivo CSV baixado com sucesso!",
      duration: 5,
    });
  };

  return (
    <PageContainer route="Compras">
      <>
        {(!store && allOrders) ||
        (store && purchases.length !== 0) ||
        (store && searchValue) ? (
          <>
            <BodyList>
              <DisabledFlag isStoreActive={isStoreActive} />

              <RowHeaderList gutter={4}>
                <ColHeaderList sm={5} xs={24}>
                  <RangePickerAnt
                    placeholder={["Data inicial", "Data final"]}
                    locale={locale}
                    format="DD/MM/YYYY"
                    value={[date.data_inicial, date.data_final]}
                    onChange={(dates) => {
                      if (dates && dates[0] && dates[1]) {
                        setSelectedDate(true);
                        setDate({
                          data_inicial: dates[0],
                          data_final: dates[1],
                        });
                        setShouldSearch(true);
                      }
                    }}
                  />
                </ColHeaderList>

                <ColHeaderList sm={5} xs={24}>
                  <Input
                    placeholder="Buscar por fornecedor"
                    suffix={<LupaIcon />}
                    style={{
                      marginBottom: window.outerWidth < 768 ? ".5rem" : "0",
                    }}
                    value={searchValue}
                    onChange={({ target: { value } }) =>
                      handleSearchChange(value)
                    }
                  />
                </ColHeaderList>
                <ColHeaderList
                  sm={4}
                  xs={24}
                  style={{
                    marginBottom: window.outerWidth < 768 ? ".5rem" : "0",
                  }}
                >
                  <GetStore
                    defaultValue={store}
                    showAllStore={true}
                    allStores={setAllOrders}
                    handleChange={(id) => {
                      setStore(id);
                      setFilter({
                        data_inicial: moment(),
                        data_final: moment().add(1, "days"),
                        value_min: undefined,
                        value_max: undefined,
                        category: undefined,
                        product: undefined,
                        status: undefined,
                      });
                      setShouldSearch(true);
                    }}
                  />
                </ColHeaderList>

                <ColHeaderListButtons
                  sm={0}
                  md={!allOrders ? 1 : 0}
                  xs={0}
                  style={{ flex: "0" }}
                >
                  {verifyPermission("shop.purchases_report") && !allOrders && (
                    <Tooltip title={"Relatório de Compras"}>
                      <ButtonFilter
                        onClick={() => history.push(`/shop_report/${store}`)}
                      >
                        <GraphIcon />
                      </ButtonFilter>
                    </Tooltip>
                  )}
                </ColHeaderListButtons>

                <ColHeaderListButtons sm={1} xs={24}>
                  {(allOrders || store) && (
                    <Tooltip title={"Filtro"}>
                      <ButtonFilter
                        onClick={() => {
                          setVisible(true);
                          setCheck(allOrders);
                        }}
                      >
                        <FilterIcon />
                      </ButtonFilter>
                    </Tooltip>
                  )}
                </ColHeaderListButtons>

                <ColHeaderListButtons sm={1} xs={24}>
                  {(allOrders || store) && (
                    <Tooltip title={"Download CSV"}>
                      <ButtonFilter onClick={() => downloadCSV()}>
                        <DownloadIcon />
                      </ButtonFilter>
                    </Tooltip>
                  )}
                </ColHeaderListButtons>

                {!isStoreActive && (
                  <ColHeaderListButtons
                    sm={store ? 1 : 0}
                    xs={store ? 24 : 0}
                    style={{ marginRight: "0" }}
                  >
                    {verifyPermission("shop.add") && store && (
                      <Tooltip title={"Nova Compra"}>
                        <StyledLink
                          to={{
                            pathname: "/new-shop",
                            state: {
                              purchasesItems,
                              purchases,
                              purchaseId,
                              productsCategory,
                              purchasesOrder,
                              store,
                            },
                          }}
                        >
                          <AddIcon />
                        </StyledLink>
                      </Tooltip>
                    )}
                  </ColHeaderListButtons>
                )}
              </RowHeaderList>
              <RowHeaderList style={{ marginTop: "0.1rem" }}>
                {loading ? (
                  <BodyList style={{ marginTop: "15%" }}>
                    <Centralizer>
                      <Spin />
                    </Centralizer>
                  </BodyList>
                ) : (
                  <>
                    <ShoppingList
                      setLoading={setLoading}
                      shouldSearch={shouldSearch}
                      loading={loading}
                      setPurchaseId={setPurchaseId}
                      purchases={filteredProvider}
                      setShouldSearch={setShouldSearch}
                      buttonInfo={infoModal}
                      isStoreActive={isStoreActive}
                      productsCategory={productsCategory}
                      store={store}
                      page={page}
                      size={size}
                      totalElements={totalElements}
                      setPaginate={setPaginate}
                      allOrders={allOrders}
                    />
                  </>
                )}
              </RowHeaderList>
            </BodyList>
          </>
        ) : (
          <>
            {!store && !allOrders ? (
              <>
                <StoreSelectionPage
                  title="Para buscar ou cadastrar uma compra"
                  Img={PurchasesImg}
                  store={store}
                  setStore={setStore}
                  setShouldSearch={setShouldSearch}
                  checkBoxVisible={true}
                  checked={allOrders}
                  setAll={setAllOrders}
                  permissionCheck="shop.all_purchases"
                  textCheckBox="Todas as compras"
                ></StoreSelectionPage>
              </>
            ) : (
              <>
                {loading ? (
                  <BodyList>
                    <Centralizer>
                      <Spin />
                    </Centralizer>
                  </BodyList>
                ) : (
                  <>
                    <StoreSelectionPage
                      title="Oops! Nenhuma compra cadastrada nesta loja."
                      textTitle="Faça o cadastro de uma nova compra ou selecione outra loja"
                      Img={NoData}
                      store={store}
                      setStore={setStore}
                      setShouldSearch={setShouldSearch}
                      checkBoxVisible={true}
                      checked={allOrders}
                      setAll={setAllOrders}
                      permissionCheck="shop.all_purchases"
                      textCheckBox="Todas as compras"
                      buttonVisible={true}
                      permissionButton="shop.add"
                      buttonClick={addFirstPurchase}
                      buttonText="Nova Compra"
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </>

      <ModalFilter
        title="Filtros"
        centered
        onCancel={() => setVisible(false)}
        visible={visible}
        width={450}
        destroyOnClose={true}
        footer={[
          <FooterFilterModal>
            <ButtonSearch
              onClick={() => {
                resetPaginate();
                searchFilter();
              }}
            >
              Buscar itens
            </ButtonSearch>

            <ButtonClear onClick={() => resetFilter()}>
              <span>Limpar Filtro</span>
            </ButtonClear>
          </FooterFilterModal>,
        ]}
      >
        <Row>
          <Col sm={12} xs={24}>
            <span>Valor Mínimo</span>
            <MonetaryInput2
              defaultValue={filter.value_min}
              getValue={(value) =>
                setFilter((oldValues) => ({ ...oldValues, value_min: +value }))
              }
            />
          </Col>
          <Col sm={12} xs={24}>
            <span>Valor Máximo</span>
            <MonetaryInput2
              defaultValue={filter.value_max}
              getValue={(value) =>
                setFilter((oldValues) => ({ ...oldValues, value_max: +value }))
              }
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <span>Categoria</span>
            <Select
              showSearch
              placeholder="Selecione uma categoria"
              optionFilterProp="children"
              value={filter.category}
              onChange={(value) =>
                setFilter((prev) => ({
                  ...prev,
                  category: +value,
                }))
              }
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {productsCategory?.map((product) => (
                <Select.Option
                  value={product.id!}
                  key={product.id}
                  label={product.name}
                >
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <span>Tipo de Pagamento</span>
            <Select
              showSearch
              placeholder="Selecione uma categoria"
              optionFilterProp="children"
              value={filter.type}
              onChange={(value) =>
                setFilter((prev) => ({
                  ...prev,
                  type: +value,
                }))
              }
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {OrderPaymentType?.map((product) => (
                <Select.Option
                  value={product.value!}
                  key={product.value}
                  label={product.label}
                >
                  {product.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </ModalFilter>

      <InfoPurchaseItemsDrawer
        visible={visibleInfo}
        setVisible={setVisibleInfo}
        purchasesOrder={purchasesOrder}
        purchases={purchases}
        productsCategory={productsCategory}
        loadingInfo={loadingInfo}
      />
    </PageContainer>
  );
};

export default withRouter(Shop);
