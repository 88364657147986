import React, { useState, useEffect, useRef } from "react";
import apiSales from "../../../services/apiSalesHandler";

import { Sale as SaleModel } from "../../../models/Sale";
import { CashHistories as CashHistoriesModel } from "../../../models/CashHistories";

import { Button, notification } from "antd";

import {
  Modal,
  LogsNfceContainer,
  LogNfceContent,
  LogNfceTitle,
  LogNfceDescription,
  Descriptions,
} from "./styles";

interface ModalSummarySalesNfceProps {
  sales: SaleModel[];
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setShouldSearch: (shouldSearch: boolean) => void;
  store: number | undefined;
  cashHistories: CashHistoriesModel[];
  countryPY?: boolean;
}

interface ISalesNfceMessage {
  type: "success" | "error";
  id: number;
  message: string;
  error_message?: string;
}

interface ISalesNfceStatus {
  totalSalesNfce: number;
  totalSalesNfceSuccess: number;
  totalSalesNfceErrors: number;
  salesNfceMessage: ISalesNfceMessage[];
}

const ModalSummaryNfce: React.FC<ModalSummarySalesNfceProps> = ({
  sales,
  visible,
  setVisible,
  setShouldSearch,
  store,
  cashHistories,
  countryPY,
}) => {
  const [loading, setLoading] = useState(false);
  const cancelEmitRef = useRef(true);
  const [salesToSend, setSalesToSend] = useState<SaleModel[]>([]);
  const [salesNfceStatus, setSalesNfceStatus] = useState<ISalesNfceStatus>({
    totalSalesNfce: 0,
    totalSalesNfceSuccess: 0,
    totalSalesNfceErrors: 0,
    salesNfceMessage: [],
  });

  useEffect(() => {
    if (visible) {
      cancelEmitRef.current = false;
      const _sales = sales.filter(
        (_sale) => !_sale.nfce_url && !_sale.deleted_at
      );
      setSalesToSend(_sales);
      setSalesNfceStatus({
        totalSalesNfce: _sales.length,
        totalSalesNfceSuccess: 0,
        totalSalesNfceErrors: 0,
        salesNfceMessage: [],
      });
    } else {
      cancelEmitRef.current = true;
    }
  }, [visible, sales]);

  function downloadLogs(_logs: any[]) {
    const content = _logs.reduce(
      (total, log) =>
        total + `[${log.id}]: ${log.message}\n${log.error_message}\n`,
      ""
    );

    const blob = new Blob([content], { type: "text/plain" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "logs_nfces.txt";

    link.click();

    URL.revokeObjectURL(link.href);
  }

  const totalSold = (sale: SaleModel): number => {
    const totalPayments = sale.payments.reduce((total, payment) => {
      const paymentAmount = Number(payment?.amount) || 0;
      return total + paymentAmount;
    }, 0);

    const changeAmount = Number(sale?.change_amount) || 0;

    const totalAmount = totalPayments - changeAmount;

    return totalAmount;
  };

  const onSendNfces = async (): Promise<void> => {
    cancelEmitRef.current = false;
    setLoading(true);

    setSalesNfceStatus((oldVaues) => ({
      ...oldVaues,
      salesNfceMessage: [],
    }));

    let logs: ISalesNfceMessage[] = [];
    let _newSales = [...salesToSend];

    for (const _sale of salesToSend) {
      if (cancelEmitRef.current) {
        setLoading(false);
        break;
      }
      let nfcePayload;
      let apiEndpoint;

      if (countryPY) {
        const cashHistory = cashHistories.find(
          (history) => history.id === _sale.cash_history_id
        );

        nfcePayload = {
          ruc: _sale.ruc,
          check_digit_ruc: Number(_sale.check_digit_ruc) || null,
          email: _sale.email,
          type_receptor: _sale.type_receptor ? _sale.type_receptor : 0,
          type_taxpayer: _sale.type_taxpayer === null ? 2 : _sale.type_taxpayer,
          type_document_py:
            _sale.type_receptor === 1 || _sale.type_receptor === 2
              ? null
              : _sale.type_document_py === null
              ? 1
              : _sale?.type_document_py,
          id_document_py:
            _sale.type_receptor === 1 || _sale.type_receptor === 2
              ? null
              : _sale.id_document_py === null && _sale.ruc === null
              ? "9999999"
              : _sale?.id_document_py,
          exchange_rate_guarani: +(cashHistory?.pyg_exchange_rate || 1),
          name_receptor:
            _sale.type_receptor === 0
              ? "Sin Nombre"
              : String(_sale.name_receptor || "Sin Nombre"),

          store_id: store,
          total: totalSold(_sale),
          discount: +_sale.discount,
          change_amount: +_sale.change_amount,
          items: _sale.items.map((product) => ({
            product_store_id:
              product.store_product_id || product.product_store_id,
            price_sell: product.total,
            quantity: product.quantity,
          })),
          payments: _sale.payments.map((payment) => ({
            amount: payment.amount,
            type: payment.type,
            ...(payment.type === 1 || payment.type === 2
              ? { flag_card: payment.flag_card === 0 ? 99 : payment.flag_card }
              : {}),
            type_currency: payment.type_currency,
            currency_amount: payment.currency_amount,
            exchange_rate: payment.exchange_rate,
            exchange_rate_currency_to_guarani:
              payment.type_currency === 0
                ? null
                : payment.type_currency === 1
                ? (1 / +(cashHistory?.pyg_exchange_rate || 1)) *
                  (payment?.exchange_rate || 0)
                : payment.type_currency === 2
                ? 1 / +(cashHistory?.pyg_exchange_rate || 1)
                : payment.type_currency === 3
                ? 1 / +(cashHistory?.ars_exchange_rate || 1)
                : null,
          })),
          ref: _sale.ref,
        };
        apiEndpoint = "/sales/nfe/authorize-invoice-py";
      } else {
        nfcePayload = {
          cpf: _sale.cpf,
          email: _sale.email,
          store_id: store,
          total: totalSold(_sale),
          discount: +_sale.discount,
          change_amount: +_sale.change_amount,
          items: _sale.items.map((product) => ({
            product_store_id:
              product.store_product_id || product.product_store_id,
            price_sell: product.total,
            quantity: product.quantity,
          })),
          payments: _sale.payments.map((payment) => ({
            amount: payment.amount,
            type: payment.type,
            ...(payment.type === 1 || payment.type === 2
              ? { flag_card: payment.flag_card === 0 ? 99 : payment.flag_card }
              : {}),
          })),
          ref: _sale.ref,
        };
        apiEndpoint = "/sales/nfce";
      }
      let response;
      try {
        response = await apiSales.post(apiEndpoint, {
          ...nfcePayload,
          sale_id: _sale.id,
          cash_history_id: _sale.cash_history_id,
        });

        logs = [
          ...logs,
          {
            type: "success",
            id: _sale.id,
            message: countryPY
              ? `Fatura Eletrónica da venda ${_sale.id} emitida`
              : `Nfc-e da venda ${_sale.id} emitida com sucesso!`,
          },
        ];

        setSalesNfceStatus((oldValues) => ({
          ...oldValues,
          totalSalesNfceSuccess: oldValues.totalSalesNfceSuccess + 1,
          salesNfceMessage: logs,
        }));
        _newSales = _newSales.filter((_saleNfce) => _saleNfce.id !== _sale.id);
      } catch (error) {
        const error_message =
          //@ts-ignore
          error.response?.data?.error?.message ||
          //@ts-ignore
          error.response?.error?.message ||
          //@ts-ignore
          error.response?.data?.message ||
          //@ts-ignore
          error.response?.message ||
          //@ts-ignore
          error.message ||
          "Um erro interno aconteceu";

        logs = [
          ...logs,
          {
            type: "error",
            id: _sale.id,
            message: countryPY
              ? `Falha ao emitir Fatura Eletrónica da venda ${_sale.id}`
              : `Falha ao emitir NFC-e da venda ${_sale.id}!`,
            error_message,
          },
        ];

        setSalesNfceStatus((oldValues) => ({
          ...oldValues,
          totalSalesNfceErrors: oldValues.totalSalesNfceErrors + 1,
          salesNfceMessage: logs,
        }));
      }

      if (response) {
        let nfce_focus_id;
        let nfce_url;

        if (countryPY) {
          const { id, caminho_xml_nota_fiscal } = response.data.responseNf;
          nfce_focus_id = id;
          nfce_url = caminho_xml_nota_fiscal;
        } else {
          const { id, caminho_xml_nota_fiscal } = response.data.nfce;
          nfce_focus_id = id;
          nfce_url = `https://api.focusnfe.com.br${caminho_xml_nota_fiscal}`;
        }

        try {
          await apiSales.put(`/sales/${_sale.id}`, {
            nfce_focus_id: nfce_focus_id,
            nfce_url: nfce_url,
          });
        } catch (error) {
          notification.warning({
            message: `${
              countryPY
                ? `Fatura Eletrónica emitida, mas houve falha ao atualizar a venda.`
                : `NFC-e emitida, mas houve falha ao atualizar a venda.`
            }`,
            description: `Por favor, entre em contato com o suporte informando a chave gerada: ${nfce_focus_id}`,
            duration: 5,
          });
          return;
        }
      }
    }

    if (logs.some((message) => message.type === "error")) {
      downloadLogs(logs);
    }
    setSalesToSend(() => _newSales);
    setLoading(false);
    return;
  };

  const handleClose = () => {
    setShouldSearch(true);
    setVisible(false);
  };

  return (
    <Modal
      title="Reemissao de NFC-e"
      visible={visible}
      onCancel={() => handleClose()}
      closable={loading ? false : true}
      maskClosable={false}
      footer={[
        <div>
          <Button
            key="cancel"
            onClick={() =>
              loading ? (cancelEmitRef.current = true) : handleClose()
            }
          >
            {loading ? "Cancelar" : "Fechar"}
          </Button>

          <Button
            key="confirm"
            type="primary"
            onClick={() => onSendNfces()}
            loading={loading}
            disabled={!salesToSend.length}
          >
            Reemitir NFC-es
          </Button>
        </div>,
      ]}
      width={600}
    >
      <Descriptions bordered column={1} size="small">
        <Descriptions.Item label="Total de NFC-es">
          {salesNfceStatus.totalSalesNfce}
        </Descriptions.Item>
        <Descriptions.Item label="Total NFC-es emitidas com sucesso">
          {salesNfceStatus.totalSalesNfceSuccess}
        </Descriptions.Item>
        <Descriptions.Item
          label="Total de falhas na emissão"
          style={{
            color: salesNfceStatus.totalSalesNfceErrors > 0 ? "red" : "",
          }}
        >
          {salesNfceStatus.totalSalesNfceErrors}
        </Descriptions.Item>
      </Descriptions>
      <LogsNfceContainer>
        {salesNfceStatus.salesNfceMessage.map((integrationMessage) => (
          <LogNfceContent key={integrationMessage.id}>
            <LogNfceTitle>
              [
              <strong
                style={{
                  color:
                    integrationMessage.type === "success" ? "green" : "red",
                }}
              >
                {integrationMessage.id}
              </strong>
              ]: {integrationMessage.message}
            </LogNfceTitle>
            {integrationMessage.error_message ? (
              <LogNfceDescription>
                {integrationMessage.error_message}
              </LogNfceDescription>
            ) : (
              <></>
            )}
          </LogNfceContent>
        ))}
      </LogsNfceContainer>
    </Modal>
  );
};

export default ModalSummaryNfce;
