import React, { useEffect, useState } from "react";
import api from "../../../services/api";

import { verifyPermission } from "../../../services/auth";
import { currencyFormater } from "../../../services/currencyFormater";

import { Order as OrderModel } from "../../../models/Order/Order";

import Spinner from "../../../components/Spinner";

import moment from "moment";

import { Tabs, notification, Table, Button, message, Popover } from "antd";

import { Container, Modal } from "./styles";
import { InvoicesDTO } from "../../../models/Order/InvoicesDTO";
import { BankslipsDTO } from "../../../models/Order/BankslipsDTO";

interface ModalOrderDocumentsProps {
  visible: boolean;
  onClose: () => void;
  order?: OrderModel;
}

const statusLabel = {
  A: "Em Aberto",
  B: "Pago",
};

const ModalOrderDocuments: React.FC<ModalOrderDocumentsProps> = ({
  visible,
  onClose,
  order,
}) => {
  const [loading, setLoading] = useState(false);

  const [invoices, setInvoices] = useState<InvoicesDTO[]>([]);
  const [bankslips, setBankslips] = useState<BankslipsDTO[]>([]);

  const [downloading, setDownloading] = useState<{
    [key: string]: { pdf?: boolean; xml?: boolean };
  }>({});

  useEffect(() => {
    const getOrderInfo = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/orders/${order?.id}`);

        setInvoices(data?.invoices || []);
        setBankslips(data?.bank_slips || []);
      } catch (error) {
        notification.error({
          message: "Falha ao buscar documentos",
          description:
            //@ts-ignore
            error?.response?.data?.error?.message ||
            //@ts-ignore
            error.message ||
            "Um erro inesperado ocorreu!",
          duration: 5,
        });
      } finally {
        setLoading(false);
      }
    };
    if (visible && order) {
      getOrderInfo();
    }
  }, [visible, order]);

  const setFileDownloading = (
    id: string,
    fileType: "pdf" | "xml",
    value: boolean
  ) => {
    setDownloading((prev) => ({
      ...prev,
      [id]: {
        ...(prev[id] || {}),
        [fileType]: value,
      },
    }));
  };

  const isDownloading = (id: string, fileType: "pdf" | "xml") => {
    return downloading[id]?.[fileType] === true;
  };

  const downloadFile = async (
    invoiceNumber: string,
    fileType: "pdf" | "xml"
  ) => {
    setFileDownloading(invoiceNumber, fileType, true);
    try {
      const response = await api.get(
        `/order/attachment/${invoiceNumber}/${fileType}`,
        {
          responseType: "blob",
        }
      );

      const mimeType =
        fileType === "pdf" ? "application/pdf" : "application/xml";
      const fileExtension = fileType === "pdf" ? "pdf" : "xml";
      const file = new Blob([response.data], { type: mimeType });
      const url = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${invoiceNumber}.${fileExtension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      //@ts-ignore
      if (error.response && error.response.data) {
        //@ts-ignore
        const errorData = await error.response.data.text();
        const parsedError = JSON.parse(errorData);
        notification.error({
          message: `Falha ao baixar o ${fileType.toUpperCase()}`,
          description: parsedError.message || "Erro desconhecido.",
          duration: 5,
        });
      } else {
        notification.error({
          message: `Falha ao baixar o ${fileType.toUpperCase()}`,
          description: "Erro desconhecido ao processar o erro da API.",
          duration: 5,
        });
      }
    } finally {
      setFileDownloading(invoiceNumber, fileType, false);
    }
  };

  const copyCode = (code: string) => {
    if (!code) return;
    navigator.clipboard.writeText(code).then(() => {
      message.success("Código digitável copiado!");
    });
  };

  const formatDate = (date: string | undefined): string => {
    if (!date) return "-";
    return moment(date, "YYYYMMDD").format("DD/MM/YYYY");
  };

  const invoiceColumns = [
    {
      title: "Número da Nota",
      dataIndex: "f2_doc",
      key: "f2_doc",
      render: (text: string) => text || "-",
    },
    {
      title: "Série",
      dataIndex: "f2_serie",
      key: "f2_serie",
      render: (text: string) => text || "-",
    },
    {
      title: "Chave",
      dataIndex: "f2_chvnfe",
      key: "f2_chvnfe",
      render: (text: string) => text || "-",
    },
    {
      title: "Valor Faturado",
      dataIndex: "f2_valfat",
      key: "f2_valfat",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(+value || 0)}` : "-",
    },
    {
      title: "Mensagem Informações Adicionais",
      dataIndex: "f2_mennota",
      key: "f2_mennota",
      render: (text: string) => text || "-",
    },
    {
      title: "Data de Emissão",
      dataIndex: "f2_emissao",
      key: "f2_emissao",
      render: (text: string) => formatDate(text),
    },
    {
      title: "Ações",
      key: "acoes",
      render: (_: any, record: InvoicesDTO) => {
        const invNumber = record.f2_chvnfe || "";
        return invNumber ? (
          <div style={{ display: "flex", gap: "8px" }}>
            <Button
              type="primary"
              loading={isDownloading(invNumber, "pdf")}
              onClick={() => downloadFile(invNumber, "pdf")}
            >
              PDF
            </Button>
            <Button
              loading={isDownloading(invNumber, "xml")}
              onClick={() => downloadFile(invNumber, "xml")}
            >
              XML
            </Button>
          </div>
        ) : null;
      },
    },
  ];

  const bankslipColumns = [
    {
      title: "Número do Boleto",
      dataIndex: "e1_num",
      key: "e1_num",
      render: (text: string) => text || "-",
    },
    {
      title: "Série",
      dataIndex: "e1_serie",
      key: "e1_serie",
      render: (text: string) => text || "-",
    },
    {
      title: "Status",
      dataIndex: "e1_status",
      key: "e1_status",
      render: (status: string, record: BankslipsDTO) => {
        const label = statusLabel[status as "A" | "B"] || "-";
        const color = status === "B" ? "green" : "inherit";
        return <span style={{ color, fontWeight: "bold" }}>{label}</span>;
      },
    },
    {
      title: "Data de Emissão",
      dataIndex: "e1_emissao",
      key: "e1_emissao",
      render: (text: string) => formatDate(text),
    },
    {
      title: "Data de Vencimento",
      dataIndex: "e1_vencto",
      key: "e1_vencto",
      render: (text: string, record: BankslipsDTO) => {
        const today = moment();
        const dueDate = moment(text, "YYYYMMDD");

        if (!dueDate.isValid()) return "-";

        const daysDifference = dueDate.diff(today, "days");

        let color = "inherit";
        if (record.e1_status !== "B") {
          if (daysDifference < 0) {
            color = "red";
          } else if (daysDifference <= 3) {
            color = "orange";
          }
        }

        return <span style={{ color }}>{dueDate.format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Código Digitável",
      dataIndex: "e1_coddig",
      key: "e1_coddig",
      render: (text: string) =>
        text ? (
          <Popover content={text}>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <span>{`${text.substring(0, 10)}...`}</span>
              <Button size="small" onClick={() => copyCode(text)}>
                Copiar
              </Button>
            </div>
          </Popover>
        ) : (
          "-"
        ),
    },
    {
      title: "Valor",
      dataIndex: "e1_valor",
      key: "e1_valor",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(+value || 0)}` : "-",
    },
    {
      title: "Desconto",
      dataIndex: "e1_descont",
      key: "e1_descont",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(+value || 0)}` : "-",
    },
    {
      title: "Juros",
      dataIndex: "e1_juros",
      key: "e1_juros",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(+value || 0)}` : "-",
    },
    {
      title: "Multa",
      dataIndex: "e1_multa",
      key: "e1_multa",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(+value || 0)}` : "-",
    },
    {
      title: "Correção",
      dataIndex: "e1_correc",
      key: "e1_correc",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(+value || 0)}` : "-",
    },
    {
      title: "Valor Pago",
      dataIndex: "e1_valliq",
      key: "e1_valliq",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(+value || 0)}` : "-",
    },
    {
      title: "Saldo",
      dataIndex: "e1_saldo",
      key: "e1_saldo",
      render: (value: number) =>
        value ? `R$ ${currencyFormater(+value || 0)}` : "-",
    },
  ];

  const getModalWidth = () => {
    if (window.outerWidth < 768) {
      return "100%";
    } else if (window.outerWidth < 1400) {
      return "70%";
    }
    return "90%";
  };

  return (
    <Modal
      visible={visible}
      footer={
        <>
          <Button
            onClick={() => {
              if (!loading) {
                onClose();
              } else {
                notification.warning({
                  message: "Aguarde",
                  description:
                    "As informações ainda estão sendo carregadas. Por favor, aguarde a conclusão.",
                  duration: 3,
                });
              }
            }}
          >
            Fechar
          </Button>
        </>
      }
      closable={false}
      maskClosable={false}
      title={`Documentos do Pedido ${order?.vhsys ?? ""}`}
      width={getModalWidth()}
    >
      <Tabs defaultActiveKey="invoices">
        <Tabs.TabPane tab="Notas Fiscais" key="invoices">
          {loading ? (
            <Spinner />
          ) : invoices.length === 0 ? (
            <Container>
              <p>
                Nenhuma nota fiscal disponível no momento, tente novamente mais
                tarde.
              </p>
            </Container>
          ) : (
            <Table
              columns={invoiceColumns}
              dataSource={invoices.map((inv) => ({ ...inv }))}
              pagination={false}
              scroll={{ x: true }}
            />
          )}
        </Tabs.TabPane>
        {verifyPermission("orders.document_orders") && (
          <Tabs.TabPane tab="Boletos" key="bankslips">
            {loading ? (
              <Spinner />
            ) : bankslips.length === 0 ? (
              <Container>
                <p>
                  Nenhum boleto disponível no momento, tente novamente mais
                  tarde.
                </p>
              </Container>
            ) : (
              <Table
                columns={bankslipColumns}
                dataSource={bankslips.map((b) => ({ ...b }))}
                pagination={false}
                scroll={{ x: true }}
              />
            )}
          </Tabs.TabPane>
        )}
      </Tabs>
    </Modal>
  );
};

export default ModalOrderDocuments;
