import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import { ChromePicker } from "react-color";
import odinApi from "../../services/api";

import { useWindowSize } from "../../hooks/useWindowSize";
import { useResponsiveSizeTable } from "../../hooks/useResponsiveSizeTable";
import { removeAccentsAndLowerCase } from "../../utils/removeAccentsAndCaseSensitive";

import { Product } from "../../models/Product";
import { ProductTag } from "../../models/ProductTag";

import { notification } from "antd";
import { ColumnsType } from "antd/lib/table";

import {
  Container,
  HeaderContent,
  Modal,
  ProductTagModalContainer,
  Button,
  Input,
  ColorSelection,
  Content,
  ColorPickerContainer,
  SelectionContent,
  ListContent,
  Select,
  Table,
  Checkbox,
  SearchContent,
  InputSearch,
} from "./styles";

interface IProps {
  setShowProductTagPage: Dispatch<SetStateAction<boolean>>;
}

const ProductCategorization: React.FC<IProps> = ({ setShowProductTagPage }) => {
  const responsiveWindow = useWindowSize();
  const [selectedProductTag, setSelectedProductTag] =
    useState<ProductTag | null>(null);
  const [productTags, setProductTags] = useState<ProductTag[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [productsList, setProductsList] = useState<Product[]>([]);

  const [searchValue, setSearchValue] = useState("");

  const [openProductTagModal, setOpenProductTagModal] = useState(false);

  const [loadingProductTag, setLoadingProductTag] = useState(false);
  const [shouldFetchProductTags, setshouldFetchProductTags] = useState(false);

  const [loadingProduct, setLoadingProduct] = useState(false);
  const [shouldFetchProduct, setshouldFetchProduct] = useState(true);

  const [openColorPicker, setOpenColorPicker] = useState(false);

  const [name, setName] = useState("");
  const [color, setColor] = useState("#ffffff");

  const getAxiosError = (error): string => {
    return (
      error.response?.data?.error?.message ||
      error.response?.error?.message ||
      error.response?.data?.message ||
      error.response?.message ||
      error.message ||
      "Um erro interno aconteceu"
    );
  };

  useEffect(() => {
    async function fetchProductTags() {
      try {
        setLoadingProductTag(true);
        const {
          data: { message, content },
        } = await odinApi.get("/products/list-product-tags");

        const allTags: ProductTag[] = [
          {
            id: 0,
            name: "Todas",
            color: "#ffffff",
            created_at: new Date(),
            updated_at: new Date(),
          },
          ...content,
        ];

        setSelectedProductTag(allTags[0]);
        setProductTags(allTags);

        notification.success({
          message,
          duration: 5,
        });

        setshouldFetchProductTags(false);
      } catch (error) {
        notification.error({
          message: "Ao obter lista de tags",
          description: getAxiosError(error),
          duration: 5,
        });
      } finally {
        setLoadingProductTag(false);
      }
    }
    if (shouldFetchProductTags) fetchProductTags();
  }, [shouldFetchProductTags]);

  useEffect(() => {
    async function fetchProducts() {
      try {
        setLoadingProduct(true);
        const {
          data: { data },
        } = await odinApi.get("/products");

        setProducts(data?.sort((a, b) => a.id - b.id));

        notification.success({
          message: "Listagem de produtos obitidos com sucesso",
          duration: 5,
        });

        setshouldFetchProduct(false);
      } catch (error) {
        notification.error({
          message: "Ao obter lista de produtos",
          description: getAxiosError(error),
          duration: 5,
        });
      } finally {
        setLoadingProduct(false);
      }
    }
    if (shouldFetchProduct) fetchProducts();
  }, [shouldFetchProduct]);

  useEffect(() => {
    if (!openProductTagModal) {
      setshouldFetchProductTags(true);
      setSelectedProductTag(null);
    }
  }, [openProductTagModal]);

  useEffect(() => {
    if (openProductTagModal) {
      if (selectedProductTag?.id !== 0) {
        setColor(selectedProductTag?.color as string);
        setName(selectedProductTag?.name as string);
      } else {
        setColor("#ffffff");
        setName("");
      }
    }
  }, [openProductTagModal, selectedProductTag]);

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  const addProductTag = async () => {
    try {
      setLoadingProductTag(true);

      if (name.length < 3)
        return notification.error({
          message:
            "O nome da tag do producto precisa ter pelo menos 3 caracteres",
          duration: 5,
        });

      const method = selectedProductTag?.id ? "put" : "post";
      const url = selectedProductTag?.id
        ? `/products/update-product-tag/${selectedProductTag?.id}`
        : "/products/create-product-tag";

      await odinApi[method](url, {
        name,
        color,
      });

      notification.success({
        message: "Tag do produto cadastrado com sucesso",
        duration: 5,
      });

      setOpenProductTagModal(false);
    } catch (error) {
      notification.error({
        message: "Ao obter lista de produtos",
        description: getAxiosError(error),
        duration: 5,
      });
    } finally {
      setLoadingProductTag(false);
    }
  };

  const handleSelectProductTag = (product_tag_id) => {
    const productTag = productTags.find(
      (_productTag) => _productTag.id === +product_tag_id
    );
    setSelectedProductTag(productTag || null);
  };

  const updateProductClassification = async (
    productTag: ProductTag,
    product
  ) => {
    const action = product[productTag.name] ? "disable" : "enable";

    const payload = {
      action,
      product_id: product.key,
      product_tag_id: productTag.id,
    };

    try {
      setLoadingProduct(true);

      const {
        data: { message, content },
      } = await odinApi.post("/products/product-tag-classification", payload);

      notification.success({
        message,
        duration: 5,
      });

      let updatedProductList = [...products];
      const updatedProductIndex = updatedProductList.findIndex(
        (_product) => _product.id === product.key
      );
      updatedProductList[updatedProductIndex] = content;

      setProducts(() =>
        updatedProductList?.sort((a, b) => (a.id as number) - (b.id as number))
      );
    } catch (error) {
      notification.error({
        message: "Ao obter lista de tags",
        description: getAxiosError(error),
        duration: 5,
      });
    } finally {
      setLoadingProduct(false);
    }
  };

  const generateTableColumns = (_productTags: ProductTag[]) => {
    const columns: ColumnsType<any> = [];

    columns.push({
      title: "Produto",
      dataIndex: "name",
      key: "name",
      align: "left",
      fixed: "left",
      width: 200,
    });

    _productTags
      .filter((_productTag) => _productTag.id !== 0)
      .forEach((_productTag) => {
        columns.push({
          title: _productTag.name.toUpperCase(),
          dataIndex: _productTag.name,
          key: _productTag.name,
          align: "center",
          width: 150,
          render: (_, record) => (
            <Checkbox
              checked={record[_productTag.name]}
              onClick={() => updateProductClassification(_productTag, record)}
            />
          ),
        });
      });

    return columns;
  };

  const formatTableDataSource = (
    _products: Product[],
    _selectedProductTag: ProductTag | null
  ) => {
    let dataSource: any[] = [];

    _products.forEach((_product) => {
      const data: any = {};

      data.key = _product.id;
      data.name = _product.name;

      productTags.forEach((_productTag) => {
        const thisProductHasTheTag = _product.product_tag_classifications?.find(
          (_classification) =>
            _classification?.product_tag?.id === _productTag?.id
        );

        if (thisProductHasTheTag) data[_productTag.name] = true;
        else data[_productTag.name] = false;
      });

      dataSource.push(data);
    });

    if (_selectedProductTag?.id !== 0) {
      return dataSource.filter(
        (_data) => _data[_selectedProductTag?.name as string] as boolean
      );
    } else {
      return dataSource;
    }
  };

  useEffect(() => {
    const filterResults = () => {
      if (searchValue) {
        const _productsListFilter = products.filter((_product) =>
          removeAccentsAndLowerCase(_product.name)?.includes(
            removeAccentsAndLowerCase(searchValue)
          )
        );

        setProductsList(() => [..._productsListFilter]);
      } else {
        setProductsList(() => [...products]);
      }
    };

    filterResults();
  }, [products, searchValue]);

  return (
    <>
      <Container>
        <HeaderContent>
          <SearchContent>
            <Button onClick={() => setShowProductTagPage(false)}>Voltar</Button>
            <InputSearch
              placeholder={"Filtrar produtos"}
              onChange={(event) => setSearchValue(event.target.value)}
            />
          </SearchContent>
          <SelectionContent>
            <Select
              disabled={loadingProductTag}
              placeholder="Selecione uma Tag"
              defaultValue={
                selectedProductTag ? selectedProductTag.id : undefined
              }
              value={selectedProductTag ? selectedProductTag.id : undefined}
              onChange={(id) => handleSelectProductTag(id)}
              loading={loadingProductTag}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              allowClear
              onClear={() => setSelectedProductTag(null)}
            >
              {productTags.map((productTag) => (
                <Select.Option
                  key={productTag.id}
                  value={productTag.id}
                  style={{ textTransform: "uppercase" }}
                >
                  {productTag.name}
                </Select.Option>
              ))}
            </Select>
            <Button
              onClick={() => setOpenProductTagModal(true)}
              disabled={loadingProductTag}
            >
              Criar Classificação
            </Button>
          </SelectionContent>
        </HeaderContent>
        <Content>
          <ListContent>
            <Table
              loading={loadingProduct}
              dataSource={formatTableDataSource(
                productsList,
                selectedProductTag
              )}
              columns={generateTableColumns(productTags)}
              scroll={{ y: useResponsiveSizeTable(responsiveWindow) }}
            />
          </ListContent>
        </Content>
      </Container>
      <Modal
        destroyOnClose
        visible={openProductTagModal}
        confirmLoading={loadingProductTag}
        onOk={addProductTag}
        onCancel={() => setOpenProductTagModal(false)}
      >
        <ProductTagModalContainer>
          <Input
            placeholder="Nome categoria"
            value={name}
            onChange={({ target: { value } }) => setName(value)}
          />
          <ColorSelection
            customColor={color}
            onClick={() => setOpenColorPicker((oldValue) => !oldValue)}
          />
          {openColorPicker ? (
            <ColorPickerContainer>
              <ChromePicker
                onChangeComplete={handleColorChange}
                color={color}
                disableAlpha
              />
            </ColorPickerContainer>
          ) : (
            <></>
          )}
        </ProductTagModalContainer>
      </Modal>
    </>
  );
};

export default ProductCategorization;
