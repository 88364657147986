import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import moment from "moment";

import api from "../../services/api";
import apiMsNf from "../../services/apiMsNf";
import apiMidas from "../../services/apiSalesHandler";
import { verifyPermission } from "../../services/auth";

import { Sale as SaleModel } from "../../models/Sale";
import { Audit as AuditModel } from "../../models/Audit";
import { SalesTypes } from "../../models/enums/SalesType";
import { filterType } from "../../models/enums/filterType";
import { CashHistories as CashHistoriesModel } from "../../models/CashHistories";

import Totem from "../../assets/svg/totem.svg";
import Caixa from "../../assets/svg/caixa.svg";
import Crown from "../../assets/svg/crown.svg";

import ModalAuditTef from "../../components/ModalAuditTef";
import ModalHandlers from "../../components/ModalHandlers";
import ModalSaleDetail from "../../components/ModalSaleDetail";
import ModalResendNFCeInfo from "../../components/ModalResendNFCeInfo";
import ModalItemsCashHistory from "../../components/ModalItemsCashHistory";
import { currencyFormaterPYGBRL } from "../../services/currencyFormaterPYGBRL";
import ModalCashManagementAuditoria from "../../components/ModalCashManagementAuditoria";
import ModalItemsCashHistoryMoreInfo from "../../components/ModalItemsCashHistoryMoreInfo";

import InfoNfceCashManagement from "../InfoNfceCashManagement";

import { Dropdown, Menu, message, Tooltip } from "antd";

import {
  Container,
  LabelName,
  Actions,
  MoreInfo,
  Table,
  ArrowUpIcon,
  ArrowDownIcon,
  ButtonOpenModal,
  DropUpIcon,
  DropdownIcon,
  ErrorCash,
  IconTotem,
  IconCaixa,
  IconsSVG,
  LabelDeletedSale,
  Id,
  LabelAbstractSale,
} from "./styles";
import ModalSummaryNfce from "../../pages/CashManagement/ModalSummaryNfce";

interface IProps extends RouteComponentProps {
  cashHistories: CashHistoriesModel[];
  store?: number;
  types: number[];
  loading: boolean;
  deleteSaleRow: (id: number) => void;
  setCashHistories: (cashHistories: CashHistoriesModel[]) => void;
  handleRestoreSale: (id: number) => void;
  setShouldSearch: (shouldSearch: boolean) => void;
  isStoreActive: boolean | undefined;
  countryPY?: boolean | undefined;
}

export enum AuditType {
  sale = 1,
  cash_history = 2,
  balance_history = 3,
}

const CashHistoryList: React.FC<IProps> = ({
  cashHistories,
  loading,
  types,
  deleteSaleRow,
  setCashHistories,
  handleRestoreSale,
  setShouldSearch,
  isStoreActive,
  countryPY,
  store,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [visibleHandler, setVisibleHandler] = useState(false);
  const [visibleInfoNfce, setVisibleInfoNfce] = useState(false);
  const [visibleMoreInfo, setVisibleMoreInfo] = useState(false);
  const [visibleAuditoria, setVisibleAuditoria] = useState(false);
  const [visibleSummaryNfce, setVisibleSummaryNfce] = useState(false);
  const [visibleModalAuditTef, setVisibleModalAuditTef] = useState(false);
  const [selectedCashHistory, setSelectedCashHistory] = useState<number | null>(
    null
  );
  const [selectedHistory, setSelectedHistory] =
    useState<CashHistoriesModel | null>(null);
  const [selectedSale, setSelectedSale] = useState<SaleModel | null>(null);

  const [selectedAuditoria, setSelectedAuditoria] = useState<AuditModel[]>([]);
  const [selectedAuditType, setSelectedAuditType] = useState<AuditType>();
  const [selectedRefId, setSelectedRefId] = useState<number | undefined>();
  const [pygExchangeRate, setPygExchangeRate] = useState<string | undefined>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSaleForModal, setSelectedSaleForModal] =
    useState<SaleModel | null>(null);

  useEffect(() => {
    if (!selectedHistory) return;
    const data = cashHistories.filter(
      (cashHistory) => cashHistory.id !== selectedHistory.id
    );
    const newData = [...data, selectedHistory];
    setCashHistories(newData);
  }, [selectedHistory]);

  useEffect(() => {
    const fetchCashHistory = async () => {
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await api.get(
          `/cash_history_audit/${selectedAuditType}/${selectedRefId}`
        );
        setSelectedAuditoria(data.filter((item) => !item.code_nsu));
      } catch (error) {
        const _message = "Houve um erro ao realizar busca";
        //@ts-ignore
        const _description = error?.response?.data?.error?.message;
        message.error({
          message: _message,
          description: _description,
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (visibleAuditoria && selectedAuditType && selectedRefId) {
      fetchCashHistory();
    }
  }, [visibleAuditoria, selectedAuditType, selectedRefId]);

  const identifyPeriod = (openedTime: Date) => {
    const openedHour = moment(openedTime, "DD-MM-YYYY HH:mm:ss").hour();
    if (openedHour >= 6 && openedHour < 12) {
      return "Manhã";
    } else if (openedHour >= 12 && openedHour < 18) {
      return "Tarde";
    } else {
      return "Noite";
    }
  };

  const getCashType = (cashHistory) => {
    const hasNpsScore =
      cashHistory.sales &&
      cashHistory.sales.some(
        (sale) => sale.nps_score !== null && sale.nps_score !== undefined
      );
    return hasNpsScore ? "Totem" : "Caixa";
  };

  const hasNpsScore = cashHistories.some(
    (cashHistory) =>
      cashHistory.sales &&
      cashHistory.sales.some(
        (sale) => sale.nps_score !== null && sale.nps_score !== undefined
      )
  );

  const getNfceDanfe = async (saleId: number) => {
    try {
      const api = countryPY ? apiMidas : apiMsNf;
      const route = countryPY
        ? `/sales/nfe/${saleId}/danfe/paraguai`
        : `/nfce/${saleId}/danfe`;

      const { data } = await api.get(route);

      if (countryPY && data.mime_type === "application/pdf") {
        if (data.content && data.content.length > 0) {
          const binaryString = window.atob(data.content);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);

          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          const blob = new Blob([bytes], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${saleId}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          message.error("O conteúdo do arquivo PDF está vazio.");
        }
      } else {
        const html = atob(data.content);
        const url = window.URL.createObjectURL(
          new Blob([html], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${saleId}.html`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      //@ts-ignore
      const errorMessage = error?.response?.data?.message;
      message.error(errorMessage || "Falha ao gerar arquivo.");
    }
  };

  const columns = [
    ...(hasNpsScore
      ? [
          {
            title: "Tipo de Caixa",
            dataIndex: "cash_type",
            key: "cash_type",
            width: "12%",
            render: (text, record) => {
              const cashType = getCashType(record);
              if (cashType === "Totem") {
                return (
                  <div>
                    <IconTotem src={Totem} alt="Totem" /> Totem
                  </div>
                );
              } else {
                return (
                  <div>
                    <IconCaixa src={Caixa} alt="Caixa" /> Caixa
                  </div>
                );
              }
            },
          },
        ]
      : []),
    {
      title: "Data/abertura",
      dataIndex: "opened_at",
      key: "opened_at",
      width: "22%",
      sorter: (cashId1, cashId2) => cashId1.id - cashId2.id,
      defaultSortOrder: "ascend" as any,
      render: (text, record) => (
        <Tooltip
          title={
            <>
              <span>
                {moment(text, "DD-MM-YYYY HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </span>{" "}
              <span className="amountSM">
                {`${countryPY ? `Gs.` : `R$`} ${currencyFormaterPYGBRL(
                  countryPY
                    ? +record.amount_on_open / record.pyg_exchange_rate || 0
                    : +record.amount_on_open || 0,
                  countryPY ? "PYG" : "BRL"
                )}`}
              </span>
            </>
          }
        >
          <LabelName>
            <div className="dateTime">
              <span>
                {moment(text, "DD-MM-YYYY HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </span>{" "}
            </div>
            <p className="hourTime">
              {`${countryPY ? `Gs.` : `R$`} ${currencyFormaterPYGBRL(
                countryPY
                  ? +record.amount_on_open / record.pyg_exchange_rate || 0
                  : +record.amount_on_open || 0,
                countryPY ? "PYG" : "BRL"
              )}`}
            </p>
          </LabelName>
        </Tooltip>
      ),
    },
    {
      title: "Turno",
      dataIndex: "",
      key: "",
      responsive: ["md"] as any,
      width: "10%",
      render: (_, record) => <span>{identifyPeriod(record.opened_at)}</span>,
    },
    {
      title: "Data/fechamento",
      dataIndex: "closed_at",
      key: "closed_at",
      width: "22%",
      render: (_, record) => (
        <>
          {record.closed_at !== null && record.closed_at !== undefined ? (
            <Tooltip
              title={
                <>
                  {" "}
                  <span>
                    {moment(record.closed_at, "DD-MM-YYYY HH:mm:ss").format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </span>{" "}
                  <span className="amountSM">
                    {`${countryPY ? `Gs.` : `R$`} ${currencyFormaterPYGBRL(
                      countryPY
                        ? +record.amount_on_close / record.pyg_exchange_rate ||
                            0
                        : +record.amount_on_close || 0,
                      countryPY ? "PYG" : "BRL"
                    )}`}
                  </span>
                </>
              }
            >
              <LabelName>
                <div className="dateTime">
                  <span>
                    {moment(record.closed_at, "DD-MM-YYYY HH:mm:ss").format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </span>{" "}
                </div>
                <span className="hourTime">
                  {`${countryPY ? `Gs.` : `R$`} ${currencyFormaterPYGBRL(
                    countryPY
                      ? +record.amount_on_close / record.pyg_exchange_rate || 0
                      : +record.amount_on_close || 0,
                    countryPY ? "PYG" : "BRL"
                  )}`}
                </span>
              </LabelName>
            </Tooltip>
          ) : (
            <ErrorCash>Caixa aberto</ErrorCash>
          )}
        </>
      ),
    },
    {
      title: "Saídas",
      dataIndex: "out_result",
      key: "out_result",
      responsive: ["xl"] as any,
      textWrap: "word-break",
      width: "14%",
      render: (text, record) => (
        <LabelName>
          {countryPY ? `Gs.` : `R$`}{" "}
          {currencyFormaterPYGBRL(
            countryPY
              ? +text / (record.pyg_exchange_rate || 1) || 0
              : +text || 0,
            countryPY ? "PYG" : "BRL"
          )}
        </LabelName>
      ),
    },
    {
      title: "Entrada",
      dataIndex: "in_result",
      key: "in_result",
      responsive: ["xl"] as any,
      textWrap: "word-break",
      width: "14%",
      render: (text, record) => (
        <LabelName>
          {countryPY ? `Gs.` : `R$`}{" "}
          {currencyFormaterPYGBRL(
            countryPY
              ? +text / (record.pyg_exchange_rate || 1) || 0
              : +text || 0,
            countryPY ? "PYG" : "BRL"
          )}
        </LabelName>
      ),
    },
    {
      title: "Resultados",
      dataIndex: "result_cash",
      key: "result_cash",
      responsive: ["md"] as any,
      textWrap: "word-break",
      width: "16%",
      render: (text, record) => (
        <div>
          {+text >= 0 ? <ArrowUpIcon /> : <ArrowDownIcon />}{" "}
          {countryPY ? `Gs.` : `R$`}{" "}
          {currencyFormaterPYGBRL(
            countryPY
              ? +text / (record?.pyg_exchange_rate || 1) || 0
              : +text || 0,
            countryPY ? "PYG" : "BRL"
          )}
        </div>
      ),
    },
    {
      title: "Valor faturamento",
      dataIndex: "result_cash",
      key: "result_cash_new",
      responsive: ["md"] as any,
      textWrap: "word-break",
      width: "13%",
      render: (text, record) => {
        const balanceHistory = record.balance_history;
        const totalStore = balanceHistory
          ? +(balanceHistory.total_store || "0")
          : 0;
        const totalDelivery = balanceHistory
          ? +(balanceHistory.total_delivery || "0")
          : 0;

        const total = totalStore + totalDelivery;
        const exchangeRate =
          record?.pyg_exchange_rate && record?.pyg_exchange_rate !== 0
            ? record?.pyg_exchange_rate
            : 1;

        return (
          <LabelName>{`${countryPY ? `Gs.` : `R$`} ${currencyFormaterPYGBRL(
            countryPY ? total / exchangeRate : total,
            countryPY ? "PYG" : "BRL"
          )}`}</LabelName>
        );
      },
    },
    {
      title: "Valor NFC-e Emitidas",
      dataIndex: "total_nfce_emited",
      key: "total_nfce_emited",
      responsive: ["md"] as any,
      textWrap: "word-break",
      width: "13%",
      render: (text) => {
        return (
          <LabelName>{`${countryPY ? `Gs.` : `R$`} ${currencyFormaterPYGBRL(
            text,
            countryPY ? "PYG" : "BRL"
          )}`}</LabelName>
        );
      },
    },
    ...(hasNpsScore
      ? [
          {
            title: "Média NPS Totem",
            dataIndex: "average_nps",
            key: "average_nps",
            width: "15%",
            render: (_, record) => {
              const salesNPS = record.sales.filter(
                (sale) =>
                  sale.nps_score !== null && sale.nps_score !== undefined
              );
              if (salesNPS.length > 0) {
                const totalNPS = salesNPS.reduce(
                  (total, sale) => total + sale.nps_score,
                  0
                );
                const averageNPS = totalNPS / salesNPS.length;
                const averageNpsColumn =
                  Math.abs(averageNPS - Math.round(averageNPS)) < 0.0001
                    ? Math.round(averageNPS)
                    : averageNPS.toFixed(2);
                return `${averageNpsColumn}/5`;
              } else {
                return "-";
              }
            },
          },
        ]
      : []),
    {
      title: "Ações",
      key: "action",
      width: "7%",
      render: (_, record) => {
        const saleDate = moment(record?.opened_at, "DD/MM/YYYY HH:mm:ss");
        const now = moment();
        const daysDiff = now.diff(saleDate, "days");
        return (
          <Actions>
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={
                  <Menu
                    onClick={() => {
                      setSelectedHistory(record);
                      setPygExchangeRate(record.pyg_exchange_rate);
                    }}
                  >
                    <Menu.Item
                      onClick={() => {
                        setVisible(true);
                      }}
                    >
                      Itens Excluídos
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        if (daysDiff < 3) {
                          setVisibleSummaryNfce(true);
                        }
                      }}
                      disabled={daysDiff >= 3}
                    >
                      <Tooltip
                        title={
                          daysDiff > 3
                            ? `Só é possível reenviar ${
                                countryPY ? `Fatura Eletrónica` : `NFC-e`
                              } para vendas ocorridas nos últimos 3 dias.`
                            : ""
                        }
                      >
                        <span>
                          {countryPY
                            ? "Reenviar Fatura Eletrónica"
                            : "Reemitir NFC-e"}
                        </span>
                      </Tooltip>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        setVisibleInfoNfce(true);
                      }}
                    >
                      Visualizar Valor de NFC-e Emitida
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        openCashHandlers(record.id);
                      }}
                    >
                      Movimentações
                    </Menu.Item>
                    <Menu.SubMenu title="Faturamento">
                      <Menu.Item
                        onClick={() => {
                          setVisibleMoreInfo(true);
                        }}
                      >
                        Faturamento
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          setVisibleAuditoria(true);
                          setSelectedAuditType(AuditType.balance_history);
                          setSelectedRefId(record.balance_history.id);
                        }}
                      >
                        Histórico de alterações
                      </Menu.Item>
                    </Menu.SubMenu>

                    <Menu.Item
                      onClick={() => {
                        setVisibleAuditoria(true);
                        setSelectedAuditType(AuditType.cash_history);
                        setSelectedRefId(record.id);
                      }}
                    >
                      Histórico de alterações
                    </Menu.Item>
                    {!countryPY && (
                      <Menu.Item
                        onClick={() => {
                          setVisibleModalAuditTef(true);
                          setSelectedAuditType(AuditType.cash_history);
                          setSelectedRefId(record.id);
                        }}
                      >
                        Histórico de pagamentos desfeitos TEF
                      </Menu.Item>
                    )}
                    {record.backup_url && (
                      <Menu.Item>
                        <a href={record.backup_url} download>
                          Backup database
                        </a>
                      </Menu.Item>
                    )}
                  </Menu>
                }
                trigger={["click"]}
                placement="bottomLeft"
              >
                <MoreInfo onClick={(e) => e.stopPropagation()} />
              </Dropdown>
            </div>
          </Actions>
        );
      },
    },
  ];

  const childrenColumn = (record) => {
    const cashType = getCashType(record);

    return [
      {
        title: "Identificação",
        dataIndex: "id",
        key: "id",
        render: (text, record) => (
          <Id>
            {record.items.some((_item) => +_item?.price_unit === 0) ? (
              <>
                {text}
                <Tooltip title={"Essa venda possui recompensas"}>
                  <IconsSVG src={Crown} />
                </Tooltip>
              </>
            ) : (
              text
            )}
            {record.deleted_at && <LabelDeletedSale>Excluída</LabelDeletedSale>}
            {record.abstract_sale && (
              <LabelAbstractSale>Abstrata</LabelAbstractSale>
            )}
          </Id>
        ),
      },
      {
        title: "Quantidade",
        dataIndex: "quantity",
        responsive: ["md"] as any,
        key: "quantity",
        render: (text) => <>{text}</>,
      },
      {
        title: "Data da venda",
        dataIndex: "sale_date",
        key: "sale_date",
        render: (_, record) => (
          <>{moment(record.sale_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</>
        ),
      },
      {
        title: "Hora",
        dataIndex: "sale_date",
        responsive: ["md"] as any,
        key: "sale_date",
        render: (_, record) => (
          <>
            {moment(record.sale_date, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")}
          </>
        ),
      },
      {
        title: "Total",
        dataIndex: "totalSold",
        key: "totalSold",
        render: (text, record) => (
          <>
            {countryPY ? `Gs.` : `R$`}{" "}
            {currencyFormaterPYGBRL(text, countryPY ? "PYG" : "BRL")}
          </>
        ),
      },
      {
        title: "Tipo",
        dataIndex: "type",
        key: "type",
        responsive: ["md"] as any,
        render: (_, record) => <>{SalesTypes[record.type]}</>,
      },
      {
        title: "Detalhes da venda",
        key: "details",
        responsive: ["md"] as any,
        render: (_, record) => (
          <ButtonOpenModal
            onClick={() => {
              setVisibleDetail(true);
              setSelectedSale(record);
            }}
          >
            Visualizar
          </ButtonOpenModal>
        ),
      },
      ...(cashType === "Totem"
        ? [
            {
              title: "NPS Totem",
              key: "nps_score",
              render: (_, record) => (
                <span>
                  {record?.nps_score
                    ? `${record?.nps_score}/5`
                    : "Sem avaliação"}
                </span>
              ),
            },
          ]
        : []),
      ...(!countryPY
        ? [
            {
              title: "NFCe",
              dataIndex: "nfce_url",
              key: "nfce_url",
              render: (text, record) =>
                text && !text?.includes("https://api.focusnfe.com.brnull") ? (
                  <a
                    className="nfce"
                    href={record?.nfce_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Emitida
                  </a>
                ) : (
                  <span className="not-nfce">Não emitida</span>
                ),
            },
          ]
        : [
            {
              title: "Fatura Eletrónica",
              dataIndex: "nfce_url",
              key: "nfce_url",
              render: (text, record) =>
                text ? (
                  <a
                    className="nfce"
                    href={record?.nfce_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Emitida
                  </a>
                ) : (
                  <span className="not-nfce">Não emitida</span>
                ),
            },
          ]),
      {
        title: "Ações",
        key: "action",
        render: (_, record) => {
          const saleDate = moment(record.created_at);
          const now = moment();
          const daysDiff = now.diff(saleDate, "days");

          return (
            <Actions>
              {!record?.deleted_at ? (
                <Dropdown
                  overlay={
                    <Menu>
                      {!record.nfce_focus_id && (
                        <Menu.Item
                          onClick={() => {
                            if (daysDiff <= 3) {
                              setSelectedSaleForModal(record);
                              setIsModalVisible(true);
                              setPygExchangeRate(record.pyg_exchange_rate);
                            }
                          }}
                          disabled={daysDiff > 3}
                        >
                          <Tooltip
                            title={
                              daysDiff > 3
                                ? `Só é possível reenviar ${
                                    countryPY ? `Fatura Eletrónica` : `NFC-e`
                                  } para vendas ocorridas nos últimos 3 dias.`
                                : ""
                            }
                          >
                            <span>
                              {countryPY
                                ? "Reenviar Fatura Eletrónica"
                                : "Reenviar NFC-e"}
                            </span>
                          </Tooltip>
                        </Menu.Item>
                      )}

                      <Menu.Item
                        key={record.id}
                        onClick={() => {
                          if (verifyPermission("sales.nfce")) {
                            getNfceDanfe(record.id);
                          } else {
                            message.warn(
                              "Usuário não autorizado ou URL inválida da NFCe"
                            );
                          }
                        }}
                        disabled={!record.nfce_url}
                      >
                        {countryPY
                          ? "Download Fatura Eletrônica"
                          : "Download NFCe"}
                      </Menu.Item>

                      <Menu.Item
                        onClick={() => {
                          setVisibleAuditoria(true);
                          setVisibleAuditoria(record);
                          setSelectedAuditType(AuditType.sale);
                          setSelectedRefId(record.id);
                        }}
                      >
                        Histórico de venda
                      </Menu.Item>
                      {!countryPY && (
                        <Menu.Item
                          onClick={() => {
                            setSelectedAuditType(AuditType.sale);
                            setVisibleModalAuditTef(true);
                            setSelectedRefId(record.id);
                          }}
                        >
                          Histórico de pagamentos cancelados TEF
                        </Menu.Item>
                      )}

                      <Menu.Item
                        onClick={() => deleteSaleRow(record.id)}
                        disabled={isStoreActive}
                        title={
                          cashHistories?.some(
                            (cash) => cash.closed_at !== null
                          ) && "Para excluir a venda, feche o caixa"
                        }
                      >
                        Excluir venda
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <MoreInfo />
                </Dropdown>
              ) : (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => handleRestoreSale(record.id)}>
                        Restaurar venda
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          setVisibleAuditoria(true);
                          setSelectedAuditType(AuditType.sale);
                          setSelectedRefId(record.id);
                        }}
                      >
                        Histórico de venda
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          setSelectedAuditType(AuditType.sale);
                          setVisibleModalAuditTef(true);
                          setSelectedRefId(record.id);
                        }}
                      >
                        Histórico de pagamentos cancelados TEF
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <MoreInfo />
                </Dropdown>
              )}
            </Actions>
          );
        },
      },
    ];
  };

  const totalSold = (sale: SaleModel): number => {
    let totalAmount = 0;

    const cashHistory = cashHistories.find(
      (history) => history.id === sale.cash_history_id
    );

    if (countryPY) {
      const exchangeRate = cashHistory?.pyg_exchange_rate || 1;
      totalAmount =
        sale.payments.reduce((total, item) => {
          return total + +item?.amount / +exchangeRate;
        }, 0) -
        +sale.change_amount / +exchangeRate;
    } else {
      totalAmount =
        sale.payments.reduce((total, payment) => {
          const paymentAmount = payment.amount;
          return total + +paymentAmount;
        }, 0) - +sale.change_amount;
    }

    return totalAmount;
  };

  const openCashHandlers = (cash_history_id: number) => {
    setSelectedCashHistory(cash_history_id);
    setVisibleHandler(true);
  };

  const filteredSale = (sales, _types) => {
    return sales
      .filter((_sale) => {
        if (
          _types.length === filterType.dinheiro ||
          _types.every((_type) => _type > filterType.pix)
        )
          return true;
        return _types.some((_type) =>
          _sale.payments.some((_payment) => _payment.type === _type)
        );
      })
      .filter((_sale) => {
        if (!_types.includes(filterType.tef)) return true;
        return _sale.payments.some((_payment) => _payment.code_nsu);
      })
      .filter((_sale) => {
        if (!_types.includes(filterType.abstrata)) return true;
        return _sale.abstract_sale;
      })
      .filter((_sale) => {
        if (!_types.includes(filterType.removidas)) return true;
        return !!_sale.deleted_at;
      })
      .filter((_sale) => {
        if (
          (!_types.includes(filterType.loja) &&
            !_types.includes(filterType.delivery)) ||
          (_types.includes(filterType.loja) &&
            _types.includes(filterType.delivery))
        )
          return true;
        if (_types.includes(filterType.loja))
          return _sale.type === SalesTypes.Loja;
        if (_types.includes(filterType.delivery))
          return (
            _sale.type >= SalesTypes.Ifood && _sale.type <= SalesTypes.Outros
          );
      });
  };

  const getRowClassName = (record) => {
    if (record?.deleted_at) {
      return "deleted_at";
    }
    if (record?.abstract_sale) {
      return "abstract_sale";
    }
    return "";
  };

  return (
    <Container>
      <Table
        loading={loading}
        columns={columns}
        scroll={{ y: 700 }}
        dataSource={cashHistories}
        rowKey={(item: any) => item.id}
        expandable={{
          expandIconColumnIndex: columns.length,
          expandRowByClick: true,
          expandIcon: ({ expanded, record, onExpand }) => {
            const selectedRecord: any = record;
            return (
              <span
                onClick={(e) => {
                  setSelectedHistory(selectedRecord);
                  onExpand(record, e);
                }}
              >
                {expanded ? <DropUpIcon /> : <DropdownIcon />}
              </span>
            );
          },
          expandedRowRender: (record) => (
            <Table
              className="children_table"
              columns={childrenColumn(record)}
              rowKey={(item: any) => item.id}
              dataSource={filteredSale(
                (record as { sales: CashHistoriesModel["sales"] }).sales.map(
                  (entitySales) => ({
                    ...entitySales,
                    key: entitySales.id,
                    id: entitySales.id,
                    quantity: entitySales.quantity,
                    sale_date: entitySales.created_at,
                    hour: entitySales.created_at,
                    totalSold: totalSold(entitySales),
                    type: entitySales.type,
                    items: entitySales.items,
                    payments: entitySales.payments,
                    change_amount: entitySales.change_amount,
                    discount: entitySales.discount,
                  })
                ),
                types
              )}
              pagination={false}
              rowClassName={getRowClassName}
            />
          ),
        }}
        pagination={false}
      />

      <ModalItemsCashHistory
        visible={visible}
        setVisible={setVisible}
        cashHistory={selectedHistory}
        countryPY={countryPY}
      />
      <ModalSaleDetail
        setVisible={setVisibleDetail}
        visible={visibleDetail}
        cashHistorySale={selectedSale}
        types={types}
        loading={loading}
        countryPY={countryPY}
        cashHistories={cashHistories}
      />

      <ModalHandlers
        setVisibleHandler={setVisibleHandler}
        visibleHandler={visibleHandler}
        selectedCashHistory={selectedCashHistory}
        countryPY={countryPY}
      />
      <ModalItemsCashHistoryMoreInfo
        visible={visibleMoreInfo}
        setVisible={setVisibleMoreInfo}
        cashHistory={selectedHistory}
        cashClosed={selectedHistory?.closed_at !== null}
        setShouldSearch={setShouldSearch}
        isStoreActive={isStoreActive}
        countryPY={countryPY}
      />
      <ModalCashManagementAuditoria
        visible={visibleAuditoria}
        loading={isLoading}
        setVisible={setVisibleAuditoria}
        selectedAuditoria={selectedAuditoria}
        auditType={selectedAuditType}
        countryPY={countryPY}
        pygExchangeRate={pygExchangeRate}
      />
      <ModalAuditTef
        visible={visibleModalAuditTef}
        setVisible={setVisibleModalAuditTef}
        selectedRefId={selectedRefId}
        auditType={selectedAuditType}
      />
      <ModalResendNFCeInfo
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        sale={selectedSaleForModal}
        setCashHistories={setCashHistories}
        cashHistories={cashHistories}
        store={store}
        countryPY={countryPY}
      />
      <InfoNfceCashManagement
        visible={visibleInfoNfce}
        setVisible={setVisibleInfoNfce}
        selectedHistory={selectedHistory}
      />
      <ModalSummaryNfce
        sales={selectedHistory?.sales || []}
        cashHistories={cashHistories}
        store={store}
        countryPY={countryPY}
        visible={visibleSummaryNfce}
        setVisible={setVisibleSummaryNfce}
        setShouldSearch={setShouldSearch}
      />
    </Container>
  );
};

export default withRouter(CashHistoryList);
