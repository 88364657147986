import styled from "styled-components";

import { Descriptions as DescriptionsAnt, Modal as ModalAnt } from "antd";

export const Modal = styled(ModalAnt)`
  .ant-modal-footer {
    display: flex !important;
    justify-content: space-between !important;
  }
`;

export const Descriptions = styled(DescriptionsAnt)`
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    padding: 4px 8px !important;
  }
`;

export const LogsNfceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 10rem;
  background-color: #80808038;
  overflow-y: scroll;
`;

export const LogNfceContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogNfceTitle = styled.span``;

export const LogNfceDescription = styled.span`
  padding-left: 15px;
`;
