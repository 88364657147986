import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import odinApi from "../../services/api";
import janusApi from "../../services/apiAuth";

import Centralizer from "../../containers/Centralizer";

import { notification, Spin } from "antd";
import {
  Container,
  HeaderContent,
  Content,
  Footer,
  SearchContent,
  Button,
  InputSearch,
  ActionContent,
  Table,
  Modal,
  ProductEdit,
  ProductRemove,
  Select,
  Input,
} from "./styled";

type IProps = {
  setShowProductNCMPage: Dispatch<SetStateAction<boolean>>;
};

const fileName =
  process.env.REACT_APP_ENV === "production"
    ? "order_products_ncm_table_production"
    : "order_products_ncm_table_homolog";

const BrazilStates = [
  "AC",
  "AL",
  "AM",
  "AP",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MG",
  "MS",
  "MT",
  "PA",
  "PB",
  "PE",
  "PI",
  "PR",
  "RJ",
  "RN",
  "RO",
  "RR",
  "RS",
  "SC",
  "SE",
  "SP",
  "TO",
];

const example = [
  {
    product_id: 51,
    product_name: "açaí amarena",
    ncm: "4567",
    taxation: [
      { state: "SP", value: 19.5 },
      { state: "MG", value: 12.5 },
    ],
  },
];

type DataSource = {
  key: number | string;
  id: number;
  name: string;
  value: number | string;
  children: {
    key: number | string;
    name: number | string;
    value: number | string;
  }[];
};

const ProductsNCM: React.FC<IProps> = ({ setShowProductNCMPage }) => {
  const [dataSource, setDataSource] = useState<DataSource[]>([]);
  const [dataRow, setDataRow] = useState<DataSource | null>(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [addNCM, setAddNCM] = useState({
    state: "",
    value: "",
  });
  const [filter, setFilter] = useState({
    product: "",
    ncm: "",
  });

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        let apiUrl = `/products?page=1&size=1000&permission_order=true`;

        const {
          data: {
            data: { content: products },
          },
        } = await odinApi.get(apiUrl);

        const { data: productsNcmTable } = await janusApi.get(
          `/files-management/ti/configuracoes/${fileName}.json/beautify`
        );
        console.log({ productsNcmTable });
        const _dataSource = products
          .map((_product) => {
            const productNcmTable = productsNcmTable.find(
              (_productNcmTable) => _product.id === _productNcmTable.product_id
            );

            return {
              key: _product.id,
              id: _product.id,
              name: _product.name,
              value: _product.cod_ncm,
              children:
                productNcmTable && productNcmTable?.taxation?.length
                  ? productNcmTable?.taxation.map((_taxation, _taxindex) => ({
                      key: `${_product.id}-${_taxation.state}-${_taxindex}`,
                      name: _taxation.state,
                      value: _taxation.value,
                      action: null,
                    }))
                  : null,
            };
          })
          .sort(
            (firstProduct, secondProduct) => firstProduct.id - secondProduct.id
          );

        setDataSource(_dataSource);
      } catch (e) {
        const _message =
          //@ts-ignore
          e?.response?.data?.message || "Houve um erro ao realizar busca";
        notification.error({ message: _message, duration: 5 });
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (!showModal) {
      setDataRow(null);
      setAddNCM({
        state: "",
        value: "",
      });
    }
  }, [showModal]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "50%",
      align: "center",
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      align: "center",
      width: "20%",
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: "30%",
      align: "center",
      render: (_, row) =>
        /[a-zA-Z]/.test(row.key) ? (
          <ProductRemove
            onClick={() => {
              onRemove(row);
            }}
          >
            Remover
          </ProductRemove>
        ) : (
          <ProductEdit
            onClick={() => {
              setDataRow(row);
              setShowModal(true);
            }}
          >
            Editar
          </ProductEdit>
        ),
    },
  ];

  const onFinish = async () => {
    Modal.confirm({
      title: "Atualizar da tabela de NCM",
      content: "Deseja continuar e atualizar a tabela de NCM?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        try {
          setLoading(true);

          const content = dataSource.map((_row) => {
            const taxation = _row.children?.length
              ? _row.children.map((_child) => ({
                  state: _child.name,
                  value: +_child.value,
                }))
              : null;
            return {
              product_id: _row.id,
              product_name: _row.name,
              ncm: _row.value,
              taxation,
            };
          });
          const formData = new FormData();

          const jsonBlob = new Blob([JSON.stringify(content)], {
            type: "application/json",
          });
          formData.append("file", jsonBlob, fileName + ".json");
          formData.append("owner", "ti");
          formData.append("folder", "configuracoes");
          formData.append("action", "replace");

          await janusApi.post("/files-management", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          notification.success({
            message: "Arquivo criado com sucesso",
          });
        } catch (error) {
          const _message =
            //@ts-ignore
            e?.response?.data?.message || "Houve um erro ao realizar busca";
          notification.error({ message: _message, duration: 5 });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const onFilter = (
    _dataSource: DataSource[],
    _filter: {
      product: string;
      ncm: string;
    }
  ) => {
    if (_filter.product?.length && _filter.ncm?.length) {
      return _dataSource?.filter(
        (product) =>
          product?.name
            ?.toLowerCase()
            ?.normalize("NFD")
            ?.replace(/[\u0300-\u036f]/g, "")
            ?.includes(
              _filter.product
                ?.toLowerCase()
                ?.normalize("NFD")
                ?.replace(/[\u0300-\u036f]/g, "")
            ) && product?.value?.toString().includes(_filter.ncm.toString())
      );
    } else if (_filter.product?.length) {
      return _dataSource?.filter((product) =>
        product?.name
          ?.toLowerCase()
          ?.normalize("NFD")
          ?.replace(/[\u0300-\u036f]/g, "")
          ?.includes(
            _filter.product
              ?.toLowerCase()
              ?.normalize("NFD")
              ?.replace(/[\u0300-\u036f]/g, "")
          )
      );
    } else if (_filter.ncm?.length) {
      return _dataSource?.filter((product) =>
        product?.value?.toString().includes(_filter.ncm.toString())
      );
    } else {
      return _dataSource;
    }
  };

  const onRemove = (dataRow: DataSource) => {
    Modal.confirm({
      title: "Remoção de registro da tabela de NCM",
      content: "Deseja continuar e remover este registro da tabela de NCM?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        const [productId, state] = dataRow.key.toString().split("-");
        const productIndex = dataSource.findIndex(
          (_row) => _row.id === +productId
        );

        let updatedProduct = dataSource[+productIndex];

        updatedProduct.children = updatedProduct.children?.filter(
          (_child) => !_child.key.toString().includes(`${productId}-${state}`)
        );

        //@ts-ignore
        if (!updatedProduct.children?.length) updatedProduct.children = null;

        const updatedDataSource = [
          ...dataSource.filter((_, index) => index !== +productIndex),
          updatedProduct,
        ];
        setDataSource(
          updatedDataSource.sort(
            (firstProduct, secondProduct) => firstProduct.id - secondProduct.id
          )
        );
      },
    });
  };

  const onAdd = (
    _dataRow: DataSource | null,
    _addNCM: {
      state: string;
      value: string;
    }
  ) => {
    Modal.confirm({
      title: "Adição de registro da tabela de NCM",
      content: "Deseja continuar e adicionar este registro da tabela de NCM?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        const [productId] = (_dataRow as DataSource).key.toString().split("-");
        const productIndex = dataSource.findIndex(
          (_row) => _row.id === +productId
        );

        const newChild = {
          key: `${_dataRow?.id}-${_addNCM.state}-${
            _dataRow?.children?.length ? _dataRow?.children?.length + 1 : 0
          }`,
          name: _addNCM.state,
          value: _addNCM.value,
          action: null,
        };

        let updatedProduct = dataSource[+productIndex];
        if (updatedProduct.children?.length)
          updatedProduct.children = [...updatedProduct.children, newChild];
        else updatedProduct.children = [newChild];

        const updatedDataSource = [
          ...dataSource.filter((_, index) => index !== +productIndex),
          updatedProduct,
        ];
        setDataSource(
          updatedDataSource.sort(
            (firstProduct, secondProduct) => firstProduct.id - secondProduct.id
          )
        );
        setShowModal(false);
      },
    });
  };

  return (
    <Container>
      {loading ? (
        <Centralizer>
          <Spin />
        </Centralizer>
      ) : (
        <>
          <HeaderContent>
            <SearchContent>
              <Button onClick={() => setShowProductNCMPage(false)}>
                Voltar
              </Button>
              <InputSearch
                placeholder={"Filtrar produtos"}
                value={filter.product}
                onChange={({ target: { value } }) =>
                  setFilter({ ...filter, product: value })
                }
              />
              <InputSearch
                placeholder={"Filtrar NCM"}
                value={filter.ncm}
                onChange={({ target: { value } }) =>
                  setFilter({ ...filter, ncm: value })
                }
              />
            </SearchContent>
            <ActionContent></ActionContent>
          </HeaderContent>
          <Content>
            <Table
              columns={columns as any}
              dataSource={onFilter(dataSource, filter)}
            />
          </Content>
          <Footer>
            <Button onClick={onFinish}>Salvar</Button>
          </Footer>
        </>
      )}
      <Modal
        visible={showModal}
        cancelText="Cancelar"
        okText="Salvar"
        onOk={() => onAdd(dataRow, addNCM)}
        onCancel={() => setShowModal(false)}
        destroyOnClose
      >
        <div>
          <strong>
            <span style={{ fontSize: "1.5rem", textTransform: "capitalize" }}>
              {dataRow?.name}
            </span>
          </strong>
          :
          <span style={{ fontSize: "1.2rem", marginLeft: "5px" }}>
            NCM: {dataRow?.value}
          </span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Select
            style={{ width: "60%" }}
            placeholder="Selecione um Estado"
            onChange={(state) =>
              setAddNCM({ ...addNCM, state: state as string })
            }
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {BrazilStates.map((state) => (
              <Select.Option
                key={state}
                value={state}
                style={{ textTransform: "uppercase" }}
              >
                {state}
              </Select.Option>
            ))}
          </Select>
          <Input
            onChange={({ target: { value } }) =>
              setAddNCM({ ...addNCM, value: value as string })
            }
            type="number"
            placeholder="Percentual"
            style={{ width: "35%" }}
          />
        </div>
      </Modal>
    </Container>
  );
};

export default ProductsNCM;
