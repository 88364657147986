import styled, { css } from "styled-components";

import {
  Button as ButtonAnt,
  Drawer as DrawerAnt,
  Row as RowAnt,
  Col as ColAnt,
} from "antd";

import {
  CreditCard,
  Fastfood,
  Money,
  Network,
  Pix,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  FileEarmarkTextFill,
} from "../../styles/Icons";

export const Drawer = styled(DrawerAnt)`
  .ant-drawer-body {
    overflow-x: hidden;
    word-wrap: break-word;
  }
`;
export const Title = styled.h1`
  font-weight: 500;
  font-size: 2rem;
  text-transform: capitalize;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;
export const Row = styled(RowAnt)`
  display: flex;
  width: 100%;
  .table {
    flex-direction: row;
    justify-content: flex-start;
  }
  .total {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;
export const Col = styled(ColAnt)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 1rem;
    font-weight: 500;
    color: var(--blue-900);
  }
  .body {
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--blue-960);
  }
  .body-total {
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--blue-960);
  }

  a {
    cursor: pointer;
  }
`;

export const Button = styled(ButtonAnt)`
  color: black;
  background-color: white;
  border: gray 1px solid;
  width: 12.5rem;

  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0.5rem;

  border-radius: 10px;
`;

interface IType {
  type: number;
}
export const Type = styled.span<IType>`
  margin: 0 0.2rem;
  font-size: 1.1rem;
  font-weight: 400;
  ${({ type }) => {
    if (type === 0) {
      return css`
        color: var(--midori-green);
      `;
    }
    if (type === 1) {
      return css`
        color: var(--sea-capture);
      `;
    }
    if (type === 2) {
      return css`
        color: var(--c80-blue);
      `;
    }
    if (type === 3) {
      return css`
        color: var(--sweet-escape);
      `;
    }
    if (type === 4) {
      return css`
        color: var(--mountain-lake-azure);
      `;
    }
    if (type === 5) {
      return css`
        color: var(--roof-terracotta);
      `;
    }
    if (type === 6) {
      return css`
        color: var(--pix);
      `;
    }
    if (type === 7) {
      return css`
        color: var(--chanterelle);
      `;
    }
  }}
`;

const iconTableCSS = css`
  width: 1.2rem;
  height: 1.2rem;

  margin-left: 0.2rem;
`;

export const MoneyIcon = styled(Money)`
  ${iconTableCSS}
  color: var(--midori-green);
`;

export const TicketIcon = styled(Fastfood)`
  ${iconTableCSS}
  color: var(--roof-terracotta);
`;

export const OnlineIcon = styled(Network)`
  ${iconTableCSS}
  color: var(--black);
`;

export const PixIcon = styled(Pix)`
  ${iconTableCSS}
  color: var(--pix);
`;

export const DebitCardIcon = styled(CreditCard)`
  ${iconTableCSS}
  color: var(--c80-blue);
`;

export const CreditCardIcon = styled(CreditCard)`
  ${iconTableCSS}
  color: var(--sea-capture);
`;
export const ArrowLeftIcon = styled(KeyboardArrowLeft)`
  width: 2rem;
  height: 2rem;

  margin-right: 0.2rem;
  cursor: pointer;
`;

export const ArrowRightIcon = styled(KeyboardArrowRight)`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;

export const DownloadIcon = styled(FileEarmarkTextFill)`
  color: var(--orange-350);
  width: 1.2rem;
  height: 1.2rem;
`;
