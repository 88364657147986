import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import api from "../../services/api";
import apiMsNf from "../../services/apiMsNf";
import apiSalesHandler from "../../services/apiSalesHandler";
import { verifyPermission } from "../../services/auth";
import { useStoreData } from "../../hooks/useStoreData";

import { Page } from "../../models/Page";
import { CashHistories } from "../../models/CashHistories";

import NFceModal from "../../containers/NFceModal";
import Centralizer from "../../containers/Centralizer";
import CsvSalesModal from "../../containers/CsvSalesModal";
import PageContainer from "../../containers/PageContainer";
import CashHistoryList from "../../containers/CashHistoryList";

import StoreSelectionPage from "../../components/StoreSelectionPage";
import DisabledFlag from "../../components/DisabledFlag";

import CashHistoryImg from "../../assets/svg/CashManagement.svg";

import typesMenu from "./enum/typesMenu.json";

import moment from "moment";

import {
  Empty,
  Spin,
  message,
  Tooltip,
  Dropdown,
  Modal,
  Form,
  notification,
} from "antd";

import {
  Container,
  Content,
  Col,
  Select,
  Option,
  Menu,
  CheckboxMenuGroup,
  CheckboxMenu,
  FilterMenu,
  FilterIcon,
  ButtonNFCe,
  NFCeIcon,
  DownloadIcon,
  ButtonDownloadCSV,
  ButtonDownload,
  Row,
  DatePicker,
  Header,
  ColMinimum,
  ButtonRow,
  Textarea,
  CashRegisterIcon,
} from "./styles";
import CashStoreModal from "../../containers/CashStoreModal";

interface IProp extends RouteComponentProps {}

export interface NFCe {
  mes: number;
  xmls: string;
  danfes?: any;
}

const CashManagement: React.FC<IProp> = () => {
  const [store, setStore] = useState<number | null | undefined>();
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 10,
    totalElements: 0,
  });
  const { page, size } = paginate;
  const [cashHistory, setCashHistory] = useState<CashHistories[]>([]);
  const [shouldSearch, setShouldSearch] = useState(true);
  const [types, setTypes] = useState<number[]>([]);
  const [nfceModal, setNfceModal] = useState(false);
  const [csvModal, setCsvModal] = useState(false);
  const [cashes, setCashes] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());

  const [cashDetailsPY, setCashDetailsPY] = useState<CashHistories["cash"]>();
  const [cashDetails, setCashDetails] = useState<CashHistories["cash"]>();

  const [formCancelJustify] = Form.useForm();

  const { isStoreActive, loadingStore, stores, storeCountryPY } =
    useStoreData(store);

  const formattedDates = {
    data_inicial: moment(selectedDate).format("DD/MM/YYYY"),
    data_final: moment(selectedDate).add(1, "days").format("DD/MM/YYYY"),
  };

  useEffect(() => {
    const loadCashManagementData = async () => {
      setLoading(true);
      try {
        let url = `/cash_history/${store}?data_inicial=${formattedDates.data_inicial}&data_final=${formattedDates.data_final}&withDeleted=true&all_sales=true`;

        const {
          data: { data },
        } = await api.get(url);

        let apiContent = data.map((data) => ({
          ...data,
          total_nfce_emited: data?.sales?.length
            ? +data?.sales
                .filter((_sale) => _sale.nfce_url?.includes(".xml"))
                .reduce(
                  (total, sale) =>
                    total +
                    (sale.payments.reduce(
                      (_total, payment) => _total + payment.amount,
                      0
                    ) -
                      +(sale.change_amount || 0)),
                  0
                )
                .toFixed(2)
            : 0,
          balance_history: data?.balance_history
            ? {
                ...data.balance_history,
                money:
                  +data?.balance_history?.money -
                  +data?.balance_history?.change_amount,
                total_store:
                  +data?.balance_history?.total_store -
                  +data?.balance_history?.change_amount,
                total:
                  +data?.balance_history?.total -
                  +data?.balance_history?.change_amount,
              }
            : null,
        }));

        setCashHistory(apiContent || []);
        setPaginate((oldValues) => ({
          ...oldValues,
          totalElements: apiContent?.totalElements || 0,
        }));

        setLoading(false);
      } catch (error) {
        //@ts-ignore
        const _message = error?.response?.data?.message;
        message.error(_message || "Houve um erro ao realizar busca");
      } finally {
        setShouldSearch(false);
      }
    };

    if (store && shouldSearch) {
      loadCashManagementData();
    }
  }, [
    store,
    shouldSearch,
    page,
    size,
    formattedDates.data_inicial,
    formattedDates.data_final,
    types,
  ]);

  const handleRestoreSale = (saleId: number) => {
    Modal.confirm({
      title: "Restaurar venda",
      content: "Tem certeza que gostaria de restaurar esta venda?",
      okText: "Sim",
      okType: "default",
      cancelText: "Não",
      async onOk() {
        try {
          await apiSalesHandler.patch(`/sales/${saleId}`);
          message.success("Venda restaurada com sucesso");
          setShouldSearch(true);
        } catch (error) {
          const _message =
            //@ts-ignore
            error?.response?.data?.message;
          message.error(_message || "Erro ao Restaurar venda");
        }
      },
    });
  };

  const deleteSaleRow = async (id: number) => {
    const saleCashHistory = cashHistory.find((cash) => {
      return cash.sales.find((sale) => sale.id === id);
    });

    if (saleCashHistory && saleCashHistory.closed_at === null) {
      message.warning(
        "É necessário fechar o caixa antes de excluir uma venda."
      );
      return;
    }

    const saleSelect = saleCashHistory?.sales?.find((sale) => sale.id === id);
    const tefFind = saleSelect?.payments?.some(
      (_payment) => _payment?.code_nsu
    );
    const hasBrazilianNFCe = saleSelect?.nfce_focus_id;
    const hasParaguayanInvoice = storeCountryPY && saleSelect?.nfce_focus_id;

    const renderTextarea = (
      <>
        {tefFind && (
          <p>
            Esta venda possui pagamentos autorizados via TEF, ao excluí-la você
            deve entrar no <b>D-TEF Web</b> através do{" "}
            <a
              href="https://tef.linxsaas.com.br/tefweb/DTefWeb.cgi/login"
              target="_blank"
              style={{ color: "blue" }}
              rel="noopener noreferrer"
            >
              {" "}
              LINK
            </a>{" "}
            e verificar os pagamentos a serem cancelados.
          </p>
        )}
        {hasParaguayanInvoice ? (
          <Form form={formCancelJustify}>
            <span>
              Ao remover a venda, a fatura eletrônica vinculada a esta venda
              será cancelada.
            </span>
            <Form.Item
              label=""
              name="textArea"
              rules={[
                { required: true, message: "Campo obrigatório" },
                {
                  min: 15,
                  message: "A justificativa deve ter no mínimo 15 caracteres",
                },
                {
                  max: 255,
                  message: "A justificativa deve ter no máximo 255 caracteres",
                },
              ]}
            >
              <Textarea
                name="textArea"
                placeholder="A justificativa deve ter de 15 a 255 caracteres"
                minLength={15}
                maxLength={255}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Form>
        ) : hasBrazilianNFCe ? (
          <Form form={formCancelJustify}>
            <span>
              Ao remover a venda, as NFC-e vinculadas a esta venda serão
              removidas também.
            </span>
            <Form.Item
              label=""
              name="textArea"
              rules={[
                { required: true, message: "Campo obrigatório" },
                {
                  min: 15,
                  message: "A justificativa deve ter no mínimo 15 caracteres",
                },
                {
                  max: 255,
                  message: "A justificativa deve ter no máximo 255 caracteres",
                },
              ]}
            >
              <Textarea
                name="textArea"
                placeholder="A justificativa deve ter de 15 a 255 caracteres"
                minLength={15}
                maxLength={255}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Form>
        ) : (
          <p>Deseja excluir a venda?</p>
        )}
      </>
    );

    Modal.confirm({
      title: "Deseja remover venda",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      content: renderTextarea,
      async onOk() {
        await formCancelJustify.validateFields();
        try {
          const justify = formCancelJustify.getFieldValue("textArea");
          if (hasParaguayanInvoice) {
            await apiSalesHandler.post("/sales/cancel-invoice-py", {
              sale_id: id,
              justify,
              store_id: store,
            });
            message.success("Fatura eletrônica cancelada com sucesso");
          }

          const endpoint = justify
            ? `/sales/${id}?store_id=${store}&justify=${justify}`
            : `/sales/${id}`;

          await apiSalesHandler.delete(endpoint);
          notification.success({
            message: "Venda deletada com sucesso",
          });
          setCashHistory(
            cashHistory.map((data) => {
              const sales = data.sales.filter((sale) => sale.id !== id);
              return { ...data, sales };
            })
          );
          setShouldSearch(true);
        } catch (error) {
          //@ts-ignore
          const _message = error?.response?.data?.message;
          notification.error({ message: _message || "Erro ao deletar venda" });
        } finally {
          formCancelJustify.resetFields();
          setShouldSearch(true);
        }
      },
    });
  };

  const handleNfce = async (): Promise<void> => {
    if (!store) {
      message.warning("Selecione uma loja");
      return;
    }
    setNfceModal(true);
  };

  const handlePdfNfce = async (): Promise<void> => {
    setLoadingDownload(true);
    try {
      const { data_inicial, data_final } = {
        data_inicial: moment(selectedDate).format("DD/MM/YYYY"),
        data_final: moment(selectedDate).add(1, "day").format("DD/MM/YYYY"),
      };

      const { data: pdf } = await apiMsNf.get(
        `/nfce/pdf?store_id=${store}&data_inicial=${data_inicial}&data_final=${data_final}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([pdf], {
          type: "application/pdf",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch {
      message.error("Falha ao gerar arquivo.");
    } finally {
      setLoadingDownload(false);
    }
  };

  useEffect(() => {
    const handleCashDetail = async () => {
      try {
        let response;

        if (storeCountryPY) {
          response = await api.get(`store_cashes/paraguai/${store}`);
        } else {
          response = await api.get(`store_cashes/${store}`);
        }

        const {
          data: { content },
        } = response;

        if (storeCountryPY) {
          setCashDetailsPY(content);
        } else {
          setCashDetails(content);
        }
      } catch (error) {
        //@ts-ignore
        const _message = error?.response?.data?.message;
        message.error(_message || "Erro ao buscar detalhes dos caixas da loja");
      }
    };

    if (store && shouldSearch) {
      handleCashDetail();
    }
  }, [cashes, store, shouldSearch, storeCountryPY]);

  const menu = (
    <Menu>
      <Menu.Item>
        <CheckboxMenuGroup
          onChange={(type) => {
            setTypes(type as number[]);
          }}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {typesMenu.map((type) => (
            <>
              <CheckboxMenu key={type.id} value={type.id}>
                {type.description}
              </CheckboxMenu>
            </>
          ))}
        </CheckboxMenuGroup>
      </Menu.Item>
    </Menu>
  );

  return (
    <PageContainer route="Gerenciamento de Caixa">
      {!store ? (
        <StoreSelectionPage
          title="Para consultar o caixa"
          Img={CashHistoryImg}
          store={store}
          setStore={setStore}
          setShouldSearch={setShouldSearch}
        ></StoreSelectionPage>
      ) : (
        <Container>
          <DisabledFlag isStoreActive={isStoreActive} />

          <Header>
            <Row gutter={8}>
              <Col xl={6} md={5} sm={12} xs={12}>
                <Select
                  style={{ textTransform: "uppercase" }}
                  placeholder="Selecione uma loja"
                  onChange={(id) => {
                    setStore(+id);
                    setShouldSearch(true);
                  }}
                  defaultValue={store ? store : undefined}
                  loading={loadingStore}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={shouldSearch}
                >
                  {stores.map((store) => (
                    <Option
                      key={store.key}
                      value={store.key}
                      style={{ textTransform: "uppercase" }}
                    >
                      {store.value}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col xl={6} md={4} sm={12} xs={12}>
                <DatePicker
                  value={selectedDate}
                  allowClear={false}
                  format="DD/MM/YYYY"
                  onChange={(date) => {
                    setSelectedDate(date!);
                    setShouldSearch(true);
                  }}
                  disabled={shouldSearch}
                />
              </Col>

              <ButtonRow gutter={12}>
                <ColMinimum sm={1} xs={3}>
                  <Tooltip placement="bottom" title={"Filtro"}>
                    <Dropdown
                      onVisibleChange={(state) => setMenuVisible(state)}
                      visible={menuVisible}
                      overlay={menu}
                      trigger={["click"]}
                      placement="bottomCenter"
                      disabled={shouldSearch}
                    >
                      <FilterMenu disabled={shouldSearch}>
                        <FilterIcon />
                      </FilterMenu>
                    </Dropdown>
                  </Tooltip>
                </ColMinimum>

                {!storeCountryPY && verifyPermission("sales.nfce") && (
                  <ColMinimum sm={1} xs={3}>
                    <Tooltip placement="bottom" title={"Download NFCe"}>
                      <ButtonNFCe
                        onClick={() => handleNfce()}
                        disabled={shouldSearch}
                      >
                        <NFCeIcon />
                      </ButtonNFCe>
                    </Tooltip>
                  </ColMinimum>
                )}

                <ColMinimum sm={1} xs={3}>
                  <Tooltip placement="bottom" title={"Download CSV"}>
                    <ButtonDownloadCSV
                      onClick={() => setCsvModal(true)}
                      disabled={shouldSearch}
                    >
                      <DownloadIcon />
                    </ButtonDownloadCSV>
                  </Tooltip>
                </ColMinimum>

                <ColMinimum sm={1} xs={3}>
                  <Tooltip
                    placement="bottom"
                    title={"Visualizar detalhes dos caixas"}
                  >
                    <ButtonDownloadCSV
                      onClick={() => setCashes(true)}
                      disabled={shouldSearch}
                    >
                      <CashRegisterIcon />
                    </ButtonDownloadCSV>
                  </Tooltip>
                </ColMinimum>
                {!storeCountryPY && (
                  <ColMinimum sm={3} xs={7}>
                    <Tooltip placement="bottom" title={"Download PDF"}>
                      <ButtonDownload
                        onClick={() => handlePdfNfce()}
                        loading={loadingDownload}
                        disabled={shouldSearch}
                      >
                        Download relatório
                      </ButtonDownload>
                    </Tooltip>
                  </ColMinimum>
                )}
              </ButtonRow>
            </Row>
          </Header>
          {loading ? (
            <Centralizer>
              <Spin />
            </Centralizer>
          ) : (
            <Content>
              {cashHistory.length ? (
                <>
                  <CashHistoryList
                    store={store}
                    loading={loading}
                    cashHistories={cashHistory}
                    deleteSaleRow={deleteSaleRow}
                    setCashHistories={setCashHistory}
                    types={types}
                    handleRestoreSale={handleRestoreSale}
                    setShouldSearch={setShouldSearch}
                    isStoreActive={isStoreActive}
                    countryPY={storeCountryPY}
                  />
                </>
              ) : (
                <Centralizer>
                  <Empty description="Nenhum dado encontrado neste período" />
                </Centralizer>
              )}
            </Content>
          )}
        </Container>
      )}

      <NFceModal
        visible={nfceModal}
        setVisible={setNfceModal}
        store_id={store as number}
        cnpj={stores.find((_store) => _store.key === store)?.cnpj}
      />

      <CsvSalesModal
        visible={csvModal}
        setVisible={setCsvModal}
        store_id={store as number}
        cnpj={stores.find((_store) => _store.key === store)?.cnpj}
        company_name={stores.find((_store) => _store.key === store)?.value}
      />

      <CashStoreModal
        visible={cashes}
        setVisible={setCashes}
        store_id={store as number}
        company_name={stores.find((_store) => _store.key === store)?.value}
        cash_detail={storeCountryPY ? cashDetailsPY : cashDetails}
        countryPY={storeCountryPY}
        setShouldSearch={setShouldSearch}
      />
    </PageContainer>
  );
};

export default withRouter(CashManagement);
