import styled from "styled-components";

import {
  Button as ButtonAnt,
  Modal as ModalAnt,
  Input as InputAnt,
  Select as SelectAnt,
  Table as TableAnt,
} from "antd";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 15%;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 70%;
  width: 100%;
  overflow-y: scroll;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  height: 10%;
  width: 100%;
`;

export const SearchContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 3.25rem;
  width: 50%;
`;

export const ActionContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 3.25rem;
  width: 50%;
`;

export const InputSearch = styled(InputAnt)`
  height: 3.25rem;
  width: 40%;
  margin: 0 0.5rem;
`;

export const Button = styled(ButtonAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.25rem;
  width: fit-content;
  color: white;
  background: var(--orange-gradient);
  border: 1px solid white;
  border-radius: 0.5rem;
  margin: 0;
  :hover,
  :focus {
    color: white;
    border: 1px solid white;
    background: var(--orange-gradient);
    opacity: 80%;
  }
`;

export const Table = styled(TableAnt)`
  width: 100%;
  height: 100%;
`;

export const Modal = styled(ModalAnt)``;

export const Select = styled(SelectAnt)``;

export const Input = styled(InputAnt)``;

export const ProductEdit = styled.span`
  font-weight: bold;
  color: rgb(100, 97, 255);
  cursor: pointer;
`;

export const ProductRemove = styled.span`
  font-weight: bold;
  color: rgb(255, 97, 97);
  cursor: pointer;
`;
