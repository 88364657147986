export enum filterType {
  dinheiro = 0,
  credito = 1,
  debito = 2,
  ticket = 3,
  online = 4,
  transferencia = 5,
  pix = 6,
  removidas = 7,
  loja = 8,
  delivery = 9,
  tef = 10,
  abstrata = 11,
}
