import React, { useEffect, useState } from "react";

import { Moment } from "moment";

import locale from "antd/es/date-picker/locale/pt_BR";
import { Col, Form, Row, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form";

import MonetaryInput2 from "../../../../components/MonetaryInput2";

import { currencyFormater } from "../../../../services/currencyFormater";
import { removeAccentsAndLowerCase } from "../../../../utils/removeAccentsAndCaseSensitive";

import { ProductCategory } from "../../../../models/ProductCategory";
import OrderPaymentType from "../../../../models/json/OrderPaymentType.json";

import { InfoCircleIcon } from "../NewShop/styles";
import { DatePicker, Input, Select, Title, TitleTop, Validate } from "./styles";

interface IForm {
  category_id: number | null;
  product_id: number | null;
  month_competence: Moment | null;
  due_date: Moment | null;
  payment_method: number | null;
  issue_date: Moment | null;
  payment_date: Moment | null;
  cod_bankslip: string;
  additionalValue: number;
  discountValue: number;
  valueInterest: number;
  valueFreight: number;
  totalAccount: number;
  paymentValue: number;
  observation: string;
  accountsPayable?: number;
}

interface IProps {
  filteredCategories: ProductCategory[];
  form: FormInstance;
  formValues: IForm;
  record: any;
  setFormValues: React.Dispatch<React.SetStateAction<IForm>>;
}

const NewService: React.FC<IProps> = ({
  form,
  filteredCategories,
  setFormValues,
  formValues,
  record,
}) => {
  const [categorySelect, setCategorySelect] = useState<number | null>(null);
  const [selectedOcurrencyType, setSelectedOcurrencyType] = useState<
    number | null
  >(null);

  const getProductsByCategory = (categoryId: number | null) => {
    if (categoryId) {
      const selectedCategory = filteredCategories.find(
        (category) => category.id === categoryId
      );
      return selectedCategory
        ? record ? selectedCategory.products  : selectedCategory?.products?.filter((_product) =>
            _product?.product_tag_classifications?.some(
              (_item) =>
                _item?.product_tag?.name?.toLowerCase() ===
                "create_purchase_services"
            )
          )
        : [];
    } else {
      return [];
    }
  };

  const productsForSelectedCategory = getProductsByCategory(categorySelect);

  const updateFormValue = (field: string, value: number | null) => {
    setFormValues((prev) => {
      const updatedValues = { ...prev, [field]: value || 0 };
      const totalAccount = calculateTotalAccount(updatedValues);
      return { ...updatedValues, totalAccount: +totalAccount.toFixed(2) };
    });
  };

  const calculateTotalAccount = ({
    paymentValue,
    additionalValue,
    discountValue,
    valueFreight,
    valueInterest,
  }: typeof formValues) => {
    return Math.max(
      0,
      +paymentValue +
        +additionalValue +
        +valueFreight +
        +valueInterest -
        +discountValue
    );
  };

  useEffect(() => {
    //@ts-ignore
    setCategorySelect(formValues.category_id);
    form.setFieldsValue(formValues);
  }, []);

  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      totalAccount: +calculateTotalAccount(prev).toFixed(2),
    }));
  }, [
    formValues.paymentValue,
    formValues.additionalValue,
    formValues.discountValue,
    formValues.valueFreight,
    formValues.valueInterest,
  ]);

  return (
    <>
      <Row gutter={8} style={{ marginTop: "1.5rem" }}>
        <Col xs={24}>
          <TitleTop>Serviço</TitleTop>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={4} xs={24}>
          <Form.Item
            label={
              <span>
                Categoria
                <Tooltip title="Categoria do produto">
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            }
            name="category_id"
            rules={[{ required: true, message: "Campo obrigatório" }]}
          >
            <Select
              disabled={record}
              onChange={(value) => {
                form.setFieldsValue({
                  category_id: value,
                  product_id: "",
                });
                setCategorySelect(+value);
              }}
              placeholder="Selecione uma categoria"
            >
              {filteredCategories?.map((productCategory) => (
                <Select.Option
                  value={productCategory.id}
                  key={productCategory.id}
                >
                  {productCategory?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={5} xs={24}>
          <Form.Item
            label={
              <span>
                Tipo de Custo
                <Tooltip title="Categoria do produto">
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            }
            name="product_id"
            rules={[{ required: true, message: "Campo obrigatório" }]}
          >
            <Select
              disabled={
                record || !categorySelect || !productsForSelectedCategory.length
              }
              showSearch
              placeholder="Selecione um produto"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const cleanedInput = removeAccentsAndLowerCase(input);
                const cleanedOptionLabel = removeAccentsAndLowerCase(
                  option?.label ?? ""
                );
                return cleanedOptionLabel.includes(cleanedInput);
              }}
              onChange={(value) => {
                form.setFieldsValue({ product_id: value });
              }}
            >
              {productsForSelectedCategory.map((product) => (
                <Select.Option
                  value={product.id!}
                  key={product.id}
                  label={product.name}
                >
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={3} xs={24}>
          <Form.Item
            label={
              <span>
                Valor
                <Tooltip title="Preço de uma unidade do produto">
                  <InfoCircleIcon />
                </Tooltip>
                <Validate>*</Validate>
              </span>
            }
            name={`payment_amount`}
            rules={[
              {
                validator: () => {
                  return formValues.paymentValue
                    ? Promise.resolve()
                    : Promise.reject("Campo obrigatório");
                },
              },
            ]}
          >
            <MonetaryInput2
              style={{ height: "2.5rem" }}
              getValue={(value) => updateFormValue("paymentValue", +value)}
              defaultValue={formValues.paymentValue}
              disabled={record}
            />
          </Form.Item>
        </Col>

        <Col md={3} xs={24}>
          <Form.Item
            label={
              <span>
                Desconto
                <Tooltip title="Valor descontado da compra">
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            }
            name="discounts"
          >
            <MonetaryInput2
              style={{ height: "2.5rem" }}
              getValue={(value) => updateFormValue("discountValue", +value)}
              defaultValue={formValues.discountValue}
              disabled={record}
            />
          </Form.Item>
        </Col>

        <Col md={3} xs={24}>
          <Form.Item
            label={
              <span>
                Juros
                <Tooltip title="Juros adicionais ao serviço em R$">
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            }
            name={`interest_amount`}
          >
            <MonetaryInput2
              style={{ height: "2.5rem" }}
              getValue={(value) => updateFormValue("valueInterest", +value)}
              defaultValue={formValues.valueInterest}
              disabled={record}
            />
          </Form.Item>
        </Col>

        <Col md={3} xs={24}>
          <Form.Item
            label={
              <span>
                Frete
                <Tooltip title="Valor dos Juros">
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            }
            name="freight"
          >
            <MonetaryInput2
              style={{ height: "2.5rem" }}
              getValue={(value) => updateFormValue("valueFreight", +value)}
              defaultValue={formValues.valueFreight}
              disabled={record}
            />
          </Form.Item>
        </Col>

        <Col md={3} xs={24}>
          <Form.Item
            label={
              <span>
                Valor Total
                <Tooltip title="Soma total da conta (Valor + Valor Adicional - Desconto + Juros + Frete)">
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            }
            name="total"
          >
            <Input
              style={{ height: "2.5rem" }}
              readOnly
              value={formValues.totalAccount}
              placeholder={`R$ ${currencyFormater(
                +formValues.totalAccount
              ).toString()}`}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col xs={24}>
          <Title>Pagamento</Title>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col md={12} xs={24}>
          <Form.Item
            label={
              <span>
                Mês de Competência
                <Tooltip title="Mês e ano da competência">
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            }
            name="month_competence"
            rules={[{ required: true, message: "Campo obrigatório" }]}
          >
            <DatePicker
              placeholder="mm/aaaa"
              format="MM/YYYY"
              picker="month"
              name="month_competence"
              locale={locale}
            />
          </Form.Item>
        </Col>

        <Col md={12} xs={24}>
          <Form.Item
            label={
              <span>
                Ocorrência
                <Tooltip title="Preço de uma unidade do produto">
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            }
            name={`payment_condition`}
          >
            <Select
              placeholder=" À vista"
              value={1}
              disabled
              onChange={(value) => {
                form.setFieldsValue({ occurrence: 1 });
              }}
            >
              <Select.Option value={1} key={1}>
                À vista
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col md={6} xs={24}>
          <Form.Item
            label={
              <span>
                Data de Venc.
                <Tooltip title="Data de vencimento da conta">
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            }
            name="due_date"
            rules={[{ required: true, message: "Campo obrigatório" }]}
          >
            <DatePicker
              placeholder="dd/mm/aaaa"
              format="DD/MM/YYYY"
              name="due_date"
            />
          </Form.Item>
        </Col>

        <Col md={6} xs={24}>
          <Form.Item
            name={`payment_method`}
            label={
              <span>
                Forma de Pgto.
                <Tooltip title="Método de pagamento">
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            }
            rules={[{ required: true, message: "Campo obrigatório" }]}
          >
            <Select
              placeholder="Selecione uma opção"
              onChange={(value) => {
                form.setFieldsValue({ payment_method: value });
                setSelectedOcurrencyType(+value);
              }}
            >
              {OrderPaymentType?.map((type) => (
                <Select.Option value={type.value} key={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col md={6} xs={24}>
          <Form.Item
            label={
              <span>
                Data de Emissão
                <Tooltip title="Data que a conta foi oficialmente emitida">
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            }
            name="issue_date"
            rules={[{ required: true, message: "Campo obrigatório" }]}
          >
            <DatePicker
              placeholder="dd/mm/aaaa"
              format="DD/MM/YYYY"
              name="issue_date"
            />
          </Form.Item>
        </Col>
        <Col md={6} xs={24}>
          <Form.Item
            label={
              <span>
                Data do Pagamento
                <Tooltip title="Data do Pagamento (campo não obrigatório)">
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            }
            name="payment_date"
          >
            <DatePicker
              placeholder="dd/mm/aaaa"
              format="DD/MM/YYYY"
              name="payment_date"
            />
          </Form.Item>
        </Col>
      </Row>

      {selectedOcurrencyType === 5 && (
        <Row>
          <Col xs={24}>
            <Form.Item
              label={
                <span>
                  Código do Boleto
                  <Tooltip title="Mês e ano da competência">
                    <InfoCircleIcon />
                  </Tooltip>
                </span>
              }
              name="cod_bankslip"
            >
              <Input
                value={form.getFieldValue("cod_bankslip")}
                placeholder="Digite o código do boleto"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

export default NewService;
